import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import StudentPeekHeader from "../../components/atoms/StudentPeekHeader";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { wait } from "@testing-library/user-event/dist/utils";
import { getUserCompanyEvents } from "../../api/recruiter/events";
import { getUserCompanyApplications } from "../../api/recruiter/applications";
import { withRouter } from "react-router-dom";
import {
    getRecruiterStudent,
    rateStudent,
} from "../../api/recruiter/recruiter";
import Spinner from "../../components/Spinner";
import SidebarOverlayWrapper from "../../components/atoms/SidebarOverlayWrapper";

const mapDispatchToProps = (dispatch) => ({
    updateCandidate: (candidate) => {
        dispatch({
            type: "UPDATE_CANDIDATE",
            candidate,
        });
    },
});

const RecruiterApplicationPeek = ({
    open,
    close,
    authUser,
    student,
    updateCandidate,
    application,
}) => {

    const { studentData } = application ?? {};
    const { rating, favorited } = studentData ?? {};
    const job = application?.application?.job || {};
    const answers = application?.application?.answers || {};

    const [currentFavorited, setCurrentFavorited] = useState(favorited);
    const [currentRating, setCurrentRating] = useState(rating);

    const handleClose = () => {
        close();
    };

    const toggleFavoritedState = () => {
        const updatedStudent = { ...studentData };
        const newFavorited = !favorited;
        updatedStudent.company_student.favorited = newFavorited;
        updateCandidate(updatedStudent);

        setCurrentFavorited(newFavorited);
        window.analytics.track(
            `Recruiter Student ${newFavorited ? "Favorited" : "Unfavorited"}`
        );
    };

    const updateRatingHandler = async (rating) => {
        setCurrentRating(rating);
        const updatedStudent = { ...studentData };
        updatedStudent.company_student.rating = rating;
        updateCandidate(updatedStudent);

        await rateStudent(authUser, student.id, rating);
        window.analytics.track("Recruiter Student Rating Updated", {
            studentId: student.id,
            rating: rating,
        });
    };

    const currentStudent = studentData || {};
    const education = currentStudent.education || [];
    const college = education.length > 0 ? education[0] : {};

    return (
        <>
            <SidebarOverlayWrapper
                label={"Application Details"}
                open={open}
                onClose={handleClose}
            >
                <div className="flex flex-1 overflow-hidden">
                    <StudentPeekHeader
                        student={currentStudent}
                        college={college}
                        authUser={authUser}
                        updateRating={updateRatingHandler}
                        favorited={currentFavorited}
                        rating={currentRating}
                        toggleFavoritedState={toggleFavoritedState}
                        hideStats
                        className={"pt-0"}
                    />
                    <div className="mt-16 pt-6 flex-1 overflow-y-auto">
                        {job?.application_questions?.map((question, i) => {
                            const answer = !!answers ? answers[i] : "N/A";
                            return (
                                <div className='flex flex-col mb-8  p-4 rounded-lg' key={i}>
                                    <div className="flex items-center gap-2 mb-2">
                                    
                                        <p className='text-sm font-semibold text-slate-800'>
                                            {question.text}
                                        </p>
                                    </div>
                                    <p className='text-sm text-slate-600 whitespace-pre-wrap'>
                                        {answer}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </SidebarOverlayWrapper>
        </>
    );
};

export default withRouter(
    connect(null, mapDispatchToProps)(RecruiterApplicationPeek)
);
