import React from "react";
import {Button} from "../atoms/Button";


function OnboardingStepButtons({goNextStep, goPreviousStep, hideNext, nextLabel, isNextDisabled, backButtonCopy}) {

    return (
        <div className={`flex flex-row gap-3 ${hideNext && 'justify-center'}`}>
            <Button size={'lg'} variant={hideNext ? 'ghost' : 'secondary'} className='whitespace-nowrap' onClick={goPreviousStep}>
                {backButtonCopy || 'Go back'}
            </Button>
            {!hideNext &&
                <Button disabled={isNextDisabled} size={'lg'} className='w-full' onClick={goNextStep}>
                    {nextLabel || 'Next'}
                </Button>
            }
        </div>
    );
}

export default OnboardingStepButtons;
