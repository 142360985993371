import React, {PureComponent} from "react";
import SvgChevronRight from "../icons/SvgChevronRight";
import {Link, withRouter} from "react-router-dom";
import {addNote, createApplication, deleteApplication, deleteNote} from "../../api/student/applications";
import {editNetwork} from "../../api/student/networks";
import {SIDEBAR_CARD} from "../../utils/dummy";
import {connect} from "react-redux";
import SvgRemove from "../icons/SvgRemove";
import Tooltip from "../atoms/modals/Tooltip";
import {toast} from "react-toastify";
import {APP_ADMINS, TOAST_OPTIONS} from "../../utils/constants";
import CompanyLogo from "../atoms/CompanyLogo";
import {wait} from "@testing-library/user-event/dist/utils";
import Checkbox from "../atoms/Checkbox";
import TagComponent from "../atoms/TagComponent";
import {Button} from "../atoms/Button";
import SidebarOverlayWrapper from "../atoms/SidebarOverlayWrapper";
import NotetakingWidget from "../../components-recruiter/atoms/NotetakingWidget";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        companyNetworks: state.userReducer.companyNetworks,
        networks: state.userReducer.networks,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
        deleteApplication: (jobId) => {
            dispatch({
                type: "DELETE_APPLICATION",
                jobId,
            })
        },
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
    }
}

class ApplicationPeek extends PureComponent {
    state = {
        status: 'To apply',
        note: "",
        opened: false,
    }

    componentDidMount = () => {
        this.setState({status: this.props.application?.personal_status})
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.listing !== prevProps.listing) {
            this.setState({status: this.props.application?.personal_status})
        }
        if (this.props.application && (this.props.application?.personal_status !== prevProps.application?.personal_status)) {
            this.setState({status: this.props.application.personal_status})
        }
        if (!prevProps.open && this.props.open) {
            wait(10).then(() =>
                this.setState({opened: true})
            )
        }
    }

    updateApplicationStatus = () => {
        const application = this.props.application;
        const listing = this.props.listing || {};

        if (listing.apply_direct || (listing.exclusive && application.personal_status === "Applied")) {
            return
        }
        const newStatus = application.personal_status === "To apply" ? "Applied" : "To apply";
        this.props.editApplication(application.id, application.job_id, {personal_status: newStatus});
        this.setState({status: newStatus});
    }

    startApplication = async () => {
        try {
            const applicationPromise = createApplication(this.props.authUser, {
                company_id: this.props.listing.company_id,
                job_id: this.props.listing.id,
                personal_status: this.props.listing.apply_direct ? "Applied" : "To apply"

            });
            toast.promise(
                applicationPromise,
                {
                    pending: 'Adding application...',
                    success: 'Application saved to saved jobs',
                    error: 'Failed to save application to saved jobs',
                },
                TOAST_OPTIONS
            );
            const applicationResult = await applicationPromise
            if (applicationResult) {
                this.props.updateApplication(applicationResult.application);
            }
        } catch (error) {
            toast.error('Failed to save application to saved jobs', TOAST_OPTIONS);
        }
    };

    deleteApplication = async () => {
        try {
            const applicationPromise = deleteApplication(this.props.authUser, this.props.application.id);
            toast.promise(
                applicationPromise,
                {
                    pending: 'Removing application...',
                    success: 'Application successfully removed',
                    error: 'Failed to remove application',
                },
                TOAST_OPTIONS
            );
            const applicationResult = await applicationPromise
            if (applicationResult) {
                this.close();
                this.props.deleteApplication(this.props.application.job_id);
            }
        } catch (error) {
            toast.error('Failed to remove application', TOAST_OPTIONS);
        }
    };

    updateNote = (e) => {
        this.setState({note: e.target.value})
    }

    saveNote = async (note) => {
        try {
            const savePromise = addNote(this.props.authUser, this.props.application.id, note);

            toast.promise(
                savePromise,
                {
                    pending: 'Adding note...',
                    success: 'Note added!',
                    error: 'Failed to add note!',
                },
                TOAST_OPTIONS
            );
            const saveResult = await savePromise;
            if (saveResult) {
                const newApplication = {...this.props.application};
                newApplication.notes.push({text: note, created_at: new Date()});
                this.props.updateApplication(newApplication);
            }
            console.log(this.props)
            window.analytics.track("Job Notes Saved", {
              job_id: this.props.listing.id
            })
        } catch (error) {
            toast.error('Failed to add note!', TOAST_OPTIONS);
        }
    };

    deleteNote = async (index) => {
        try {
            const deletePromise = deleteNote(this.props.authUser, this.props.application.id, index);
            toast.promise(
                deletePromise,
                {
                    pending: 'Deleting note...',
                    success: 'Note deleted!',
                    error: 'Failed to delete note!',
                },
                TOAST_OPTIONS
            );

            const deleteResult = await deletePromise;
            if (deleteResult) {
                const newApplication = {...this.props.application};
                newApplication.notes.splice(index, 1);
                this.props.updateApplication(newApplication);
            }
        } catch (error) {
            console.error(error);
        }
    };

    close = () => {
        this.setState({opened: false})
        wait(150).then(() => {
            this.props.close()
        })
    }

    render() {
        const listing = this.props.listing || {};
        const company = listing.company || {};
        const application = this.props.application || {};
        const notes = application.notes || [];
        let networks = [];
        if (company.id in this.props.companyNetworks) {
            networks = this.props.companyNetworks[company.id].networks
        }
        return (
            <SidebarOverlayWrapper label={'Application Details'}
                                   width={'40vw'}
                                   open={this.props.open}
                                   onClose={this.close}>
                <div className='col-plain flex-1' style={{overflow: "hidden"}}>
                    <div>
                        {this.props.pageName !== "job" ?
                            <div className='flex flex-row gap-3'>
                                <CompanyLogo id={listing.company_id} className={'w-20 h-20'}/>
                                <div className=''>
                                    <p className='text-xl font-bold blacks-1'>
                                        {listing.name}
                                    </p>
                                    {!!listing.company_id ?
                                        <div className='row-ac primary'>
                                            <Link to={{
                                                pathname: '/company/' + listing.company_id,
                                                state: {
                                                    company_id: company.id,
                                                    previousPage: {location: "jobboard", data: {}}
                                                }
                                            }}
                                                  className='row-ac primary'>
                                                <p className='text-sm font-semibold'>
                                                    {listing.company_name}
                                                </p>
                                                <SvgChevronRight className={'w-5 h-5'}/>
                                            </Link>
                                        </div>
                                        :
                                        <div>
                                            <p className='text-md font-semibold'>
                                                {listing.company_name}
                                            </p>
                                        </div>
                                    }
                                    <div className='flex flex-row items-center gap-2 wrap mt-2'>
                                        <TagComponent type={'random'} label={listing.type}/>
                                        {!!listing.secondary_specialties && listing.secondary_specialties.length > 0 ? 
                                            <div className='flex flex-row gap-1 whitespace-nowrap flex-wrap'>
                                                {listing?.secondary_specialties.map(specialty =>
                                                    <TagComponent
                                                        label={specialty}
                                                        type={'industry'}
                                                    />
                                                )}
                                            </div>
                                            :
                                            <TagComponent
                                                label={listing.industry}
                                                type={'industry'}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={this.props.application ? 'row-jb' : ''}>
                                <p className={"body1-bold"}>
                                    {this.props.application && this.props.application.personal_status !== "To apply" ?
                                        'Recruiting Process'
                                        :
                                        ''
                                    }
                                </p>
                                {this.props.application && this.props.application.personal_status !== "To apply" ?
                                    <Link to={'/jobs?activeSubTab=Saved'}
                                          className='row-ac primary'>
                                        <p className='body2-bold m-0 mt-2'>
                                            View in Saved Jobs
                                        </p>
                                        <SvgChevronRight width={24} height={24}/>
                                    </Link>
                                    :
                                    <div className=''/>
                                }
                            </div>
                        }
                    </div>
                    <div className='flex flex-col flex-1 pt-5 overflow-y-scroll w-full gap-3'>
                        <div className={`${SIDEBAR_CARD}`}>
                            <div className='flex flex-row items-center justify-between'>
                                <p className='text-lg font-bold'>
                                    Status
                                </p>
                                <Tooltip
                                    toggleComponent={
                                        <div className=''>
                                            <Checkbox
                                                checked={this.props.application?.personal_status !== "To apply"}
                                                onClick={this.updateApplicationStatus}
                                                label={'Applied'}
                                            />
                                        </div>
                                    }
                                    label={'Update the status of your application to track your progress'}
                                />
                            </div>
                        </div>
                        {this.props.application ?
                            <NotetakingWidget
                                notes={notes}
                                onSaveNote={this.saveNote}
                                onDeleteNote={this.deleteNote}
                            />
                            : null}
                    </div>
                </div>
                {this.props.pageName !== "job" &&
                    <div className='row-ac mt-5'>
                        <div className='flex flex-row flex-1 gap-3'>
                            {!listing.deprecated &&
                                <Link to={{
                                    pathname: '/job/' + listing.id,
                                    state: {previousPage: {location: this.props.pageName, data: {}}}
                                }} className={'flex flex-1'}>
                                    <Button>
                                        View Full Job Page
                                    </Button>
                                </Link>
                            }
                            {(!listing.exclusive || APP_ADMINS.includes(this.props.user?.username)) &&
                                <Tooltip
                                    toggleComponent={
                                        <Button
                                            variant={'secondary'}
                                            icon={SvgRemove}
                                            className={`${listing.deprecated ? 'flex-1' : 'flex-[.85]'}`}
                                            onClick={() => this.deleteApplication()}>
                                            Remove
                                        </Button>
                                    }
                                    label={'Remove this job from your saved jobs.'}
                                />
                            }
                        </div>
                    </div>
                }
            </SidebarOverlayWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationPeek));
