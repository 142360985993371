import React from 'react';

function InputWrapper({ type, name, placeholder, value, onChange, label , className, disabled, inputClassName, error, ...rest}) {
    return (
        <div className={`flex flex-col gap-1 ${className}`}>
            {label && <label className='text-sm text-slate-500 font-semibold'>{label}</label>}
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                onWheel={type === 'number' ? (e) => e.target.blur() : undefined}
                className={`input-wrapper ${inputClassName} ${error ? '!border-red-400' : ''}`}
                {...rest}
            />
            {error && <p className="text-xs text-red-400 mt-1">{error}</p>}
        </div>
    );
}

export default InputWrapper;
