import React, { useState } from 'react';
import { Button } from './Button';
import { getDocUrl } from '../../api/firebase';
import { PaperClipIcon } from '@heroicons/react/24/outline';

const DownloadPdfButton = ({ pdfPath, className, variant = 'secondary', size = 'default' }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      const url = await getDocUrl(pdfPath);
      
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = pdfPath.split('/').pop(); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      onClick={handleDownload}
      loading={loading}
      variant={variant}
      size={size}
      className={className}
      icon={PaperClipIcon}
      iconPosition="right"
    />
  );
};

export default DownloadPdfButton; 