import React from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {Button} from "../../components/atoms/Button";

/**
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {Function} props.closeModal
 * @param {boolean} [props.disabled]
 * @param {Function} props.onConfirm
 * @param {string} [props.confirmText]
 * @param {string} [props.cancelText]
 * @param {string} props.title
 * @param {string} props.description
 */
const ConfirmationModal = ({
    isOpen,
    closeModal,
    disabled,
    onConfirm,
    confirmText,
    cancelText,
    title,
    description
}) => {
    return (
        <ModalComponent
            isOpen={isOpen}
            backgroundColor={'white'}
            headerToggle
            size={'sm'}
            toggle={closeModal}
            FooterComponent={
                <div className='flex flex-row items-center gap-3'>
                    <Button onClick={closeModal} variant='secondary'>
                        {cancelText || 'Cancel'}
                    </Button>
                    <Button
                        disabled={disabled}
                        onClick={onConfirm}>
                        {confirmText || 'Confirm'}
                    </Button>
                </div>
            }
            footerAlignment={'right'}
        >
            <p className='text-2xl mb-3 font-bold text-slate-900'>
                {title}
            </p>
            <p className='text-lg text-slate-700 mb-5'>
                {description}
            </p>
        </ModalComponent>
    );
};

export default ConfirmationModal;
