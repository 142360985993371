import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import CreateJobDetailsStep from "./create-job/CreateJobDetailsStep";
import ProgressStepper from "../atoms/ProgressStepper";
import CreateJobDateLocationStep from "./create-job/CreateJobDateLocationStep";
import CreateJobQualificationStep from "./create-job/CreateJobQualificationStep";
import {createJob} from "../../api/recruiter/jobs";
import {wait} from "@testing-library/user-event/dist/utils";
import { EditorState } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
import {Button} from "../../components/atoms/Button";
import {withRouter} from "react-router-dom";
import CreateJobApplicationQuestions from "./create-job/CreateJobApplicationQuestions";
import CreateJobPreviewStep from "./create-job/CreateJobPreviewStep";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const ZERO_STATE = {
    step: 1,
    jobId: uuidv4(),
    jobTitle: '',
    jobDescription: '',
    jobType: null,
    workEnvironment: "On-site",
    city: '',
    state: '',
    directOnRecruit: false,
    postToday: true,
    postOn: null,
    hasDeadline: true,
    deadline: null,
    classYears: [],
    qualifiedOnly: false,
    majors: [],
    clubs: [],
    schools: [],
    openings: null,
    url: '',
    applicationQuestions: [],
    numSteps: 5,
    stepLabels: ['Job Details', 'Date and Location', 'Questions', 'Candidate Qualification', 'Preview'],
    editorState: EditorState.createEmpty(),
    role: null,
    gender: null,
    race: [],
    veteran: false,
    lgbtq: false,
    disabled: false,
    authorized: false,
    loading: false,
    clubListIds: [],
    smartListIds: [],
    listNames: [],
    jobPdfPath: null,
    gpa: "",
};

class CreateJobModal extends Component {
    state = ZERO_STATE;

    submit = async () => {
        this.setState({loading: true});
        const tier = this.props.tier;
        const contentState = this.state.editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);

        const industry = this.state.role?.endsWith('Consulting') ? 'Consulting' : 'Finance';

        const questions = this.state.applicationQuestions.filter(question => !!question.text);

        const combinedClubIds = this.state.clubListIds.reduce((accumulator, currentObject) => {
            return accumulator.concat(currentObject.club_ids);
          }, []);
        
        const params = {
            name: this.state.jobTitle,
            title: this.state.jobTitle,
            description: htmlContent,
            type: this.state.jobType,
            city: !!this.state.city ? [this.state.city] : [],
            state: !!this.state.state ? [this.state.state] : [],
            work_model: this.state.workEnvironment,
            class_years: this.state.classYears,
            scope: tier !== "blk" ? "public" : "club",
            link: this.state.url,
            industry,
            apply_direct: this.state.directOnRecruit,
            deadline: this.state.hasDeadline ? this.state.deadline : "",
            schools: this.state.schools,
            clubs: tier !== "blk" ? combinedClubIds : ["baeZVpmndkm8fCNGFIuZ"],
            majors: this.state.majors,
            gpa: this.state.gpa,
            qualified_only: this.state.qualifiedOnly,
            openings: this.state.openings,
            exclusive: true,
            application_questions: questions,
            secondary_specialties: [this.state.role],
            specialties: [industry],
            smart_list_ids: this.state.smartListIds,
            club_list_ids: this.state.clubListIds.map(clubList => clubList.id),
            job_pdf_path: this.state.jobPdfPath,
            diversity: this.state.smartListIds.length ? {
                gender: this.state.gender,
                race: this.state.race,
                lgbtq: this.state.lgbtq,
                disabled: this.state.disabled,
                veteran: this.state.veteran,
                authorized: this.state.authorized,
            } : {}
        }
        // we only add the id if the job pdf path is set as we need them to match
        if (this.state.jobPdfPath) {
            params.id = this.state.jobId;
        }

        toast.promise(createJob(this.props.authUser, params).then(() => {
            this.props.closeModal();
            window.analytics.track("New Recruiter Job Created");
            this.props.history.push("/jobs");
        }), {
            pending: "Creating job...",
            success: "Job created successfully!",
            error: "Failed to create job."
        });
        this.setState({loading: false})
    }


    nextStep = () => {
        if (this.state.step === this.state.numSteps) {
            this.submit();
            return;
        }
        this.setState({step: this.state.step + 1})
    }

    prevStep = () => {
        if (this.state.step === 1) {
            this.close();
            return;
        }
        this.setState({step: this.state.step - 1})
    }


    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };


    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleWorkEnvironmentChange = (event) => {
        this.setState({
            workEnvironment: event.target.value,
        });
    };

    handleQualifiedOnlyChange = (boolean) => {
        this.setState({
            qualifiedOnly: boolean,
        });
    };

    toggleInput = (stateKey) => {
        this.setState((prevState) => ({
            [stateKey]: !prevState[stateKey],
        }));
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    handleSpecialtiesChange = (specialty) => {
        this.setState({specialties: [specialty]})
    }

    handleQualificationCheckbox = (inputName) => {
        this.setState({[inputName]: !this.state[inputName]});
    }

    updateSchools = (school) => {
        if (!school) {
            this.setState({schools: []})
            return
        }

        let newSchools = [...this.state.schools];
        if (this.state.schools.includes(school)) {
            const index = this.state.schools.indexOf(school);
            newSchools.splice(index, 1);
        } else {
            newSchools.push(school);
        }
        this.setState({schools: newSchools});
    }

    onJobPdfUpload = (path) => {
        this.setState({jobPdfPath: path});
    }


    updateApplicationQuestions = (text, i) => {
        const applicationQuestions = [...this.state.applicationQuestions];
        if (i >= this.state.applicationQuestions.length) {
            applicationQuestions.push({text, type: "input"})
        } else {
            applicationQuestions[i] = {text, type: "input"}
        }
        this.setState({applicationQuestions})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setState({
                    step: 1, 
                    clubs: this.props.currentClubs,
                    jobId: uuidv4()
                })
            })
        }
    }

    componentDidMount = () => {
        if (this.props.tier === 'blk') {
            this.setState({
                numSteps: 3,
                stepLabels: ['Job Details', 'Date and Location', 'Questions'],
            })
        }
    }

    switchDisabled = () => {
        switch (this.state.step) {
            case 0:
                return true;
            case 1:
                return !this.state.jobTitle || !this.state.jobType || !this.state.role;
            case 2:
                return (this.state.workEnvironment !== "Remote" ? (!this.state.city || !this.state.state) : false) || (this.state.hasDeadline ? !this.state.deadline : false);
            default:
                return false
        }
    }

    close = () => {
        this.setState(ZERO_STATE);
        this.props.closeModal()
    }

    setEditorState = (editorState) => {
        this.setState({editorState});
    }
    // TODO: this implementation and other ones that use smart lists are flawed as there is not a proper way to manage multiple smart lists
    selectSmartList = (res) => {
        if (this.state.smartListIds.includes(res.id)) {
            this.setState({
                smartListIds: this.state.smartListIds.filter(id => id !== res.id),
                schools: [],
                gpa: "",
                classYears: [],
                majors: [],
                gender: "",
                race: [],
                lgbtq: false,
                disabled: false,
                veteran: false,
                authorized: false,
                listNames: this.state.listNames.filter(name => name.id !== res.id)
            })
        } else {
            this.setState({
                smartListIds: this.state.smartListIds.concat(res.id),
                schools: res.colleges,
                gpa: res.gpa,
                classYears: res.grades,
                majors: res.majors,
                gender: res.gender,
                race: res.race,
                lgbtq: res.lgbtq,
                disabled: res.disabled,
                veteran: res.veteran,
                authorized: res.authorized,
                listNames: [...this.state.listNames, {name: res.name, id: res.id, type: "smart"}]
            })
        }
        
    }

    selectClubList = (item) => {
        const selectedKey = `clubListIds`;
        const isSelected = this.state[selectedKey].some((selectedItem) => selectedItem.id === item.id);

        if (isSelected) {
            this.setState({
                [selectedKey]: this.state[selectedKey].filter((selectedItem) => selectedItem.id !== item.id),
                listNames: this.state.listNames.filter(name => name.id !== item.id)
            });
        } else {
            this.setState({
                [selectedKey]: [...this.state[selectedKey], item],
                listNames: [...this.state.listNames, {name: item.name, id: item.id, type: "club"}]
            });
        }
    };

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Create A New Job'}
                headerToggle
                size={'lg'}
                toggle={this.close}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={this.prevStep}>
                            {this.state.step === 1 ? "Cancel" : "Back"}
                        </Button>
                        <Button onClick={this.nextStep} disabled={this.switchDisabled()} loading={this.state.loading}>
                            {this.state.step !== this.state.numSteps ? "Next" : "Submit"}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
                showScrollBar
            >
                <div id={'add-joblisting-modal'}>
                    <ProgressStepper
                        step={this.state.step}
                        stepLabels={this.state.stepLabels}
                    />
                    {this.state.step === 1 &&
                        <CreateJobDetailsStep
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            jobType={this.state.jobType}
                            role={this.state.role}
                            openings={this.state.openings}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            editorState={this.state.editorState}
                            setEditorState={this.setEditorState}
                            constants={this.props.constants}
                            onJobPdfUpload={this.onJobPdfUpload}
                            jobPdfPath={this.state.jobPdfPath}
                            jobId={this.state.jobId}
                        />
                    }
                    {this.state.step === 2 &&
                        <CreateJobDateLocationStep
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            workEnvironment={this.state.workEnvironment}
                            city={this.state.city}
                            state={this.state.state}
                            url={this.state.url}
                            toggleInput={this.toggleInput}
                            directOnRecruit={this.state.directOnRecruit}
                            postToday={this.state.postToday}
                            postOn={this.state.postOn}
                            hasDeadline={this.state.hasDeadline}
                            deadline={this.state.deadline}
                            handleDateChange={this.handleDateChange}
                            handleWorkEnvironmentChange={this.handleWorkEnvironmentChange}
                        />
                    }
                    {this.state.step === 3 &&
                        <CreateJobApplicationQuestions
                            applicationQuestions={this.state.applicationQuestions}
                            updateApplicationQuestions={this.updateApplicationQuestions}
                        />
                    }
                    {this.state.step === 4 &&
                        <CreateJobQualificationStep
                            handleSelectChange={this.handleSelectChange}
                            toggleInput={this.toggleInput}
                            qualifiedOnly={this.state.qualifiedOnly}
                            handleQualifiedOnlyChange={this.handleQualifiedOnlyChange}
                            handleInputChange={this.handleInputChange}
                            handleQualificationCheckbox={this.handleQualificationCheckbox}
                            selectSmartList={this.selectSmartList}
                            selectClubList={this.selectClubList}
                            smartListIds={this.state.smartListIds}
                            clubListIds={this.state.clubListIds}
                            listNames={this.state.listNames}
                        />
                    }
                    {this.state.step === 5 &&
                        <CreateJobPreviewStep
                            listingState={{
                                name: this.state.jobTitle,
                                city: this.state.city,
                                state: this.state.state,
                                link: this.state.url,
                                created_at: new Date().toISOString(),
                                type: this.state.jobType,
                                secondary_specialties: [this.state.role],
                                industry: this.state.role?.endsWith('Consulting') ? 'Consulting' : 'Finance',
                                deadline: this.state.hasDeadline ? this.state.deadline.toISOString() : null,
                                work_model: this.state.workEnvironment,
                                whitelisted: false,
                                exclusive: true,
                                tags: [], 
                                description: stateToHTML(this.state.editorState.getCurrentContent()),
                                application_questions: this.state.applicationQuestions
                            }}
                        />
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default withRouter(CreateJobModal);