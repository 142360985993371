import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ProfilePageExperienceWidget from "../../components/profilepage/ProfilePageExperienceWidget";
import ProfilePageEducationWidget from "../../components/profilepage/ProfilePageEducationWidget";
import SectionTabs from "../../components/atoms/SectionTabs";
import StudentPeekHeader from "../../components/atoms/StudentPeekHeader";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {wait} from "@testing-library/user-event/dist/utils";
import {Document, Page} from "react-pdf";
import {getUserCompanyEvents} from "../../api/recruiter/events";
import {getUserCompanyApplications} from "../../api/recruiter/applications";
import {withRouter} from "react-router-dom";
import {addNote, deleteNote, getRecruiterStudent, rateStudent, updateStatus} from "../../api/recruiter/recruiter";
import {getCompanyUserEmails} from "../../api/recruiter/companies";
import Tooltip from "../../components/atoms/modals/Tooltip";
import SvgSchool from "../../components/icons/SvgSchool";
import Spinner from "../../components/Spinner";
import ClubLogo from "../atoms/ClubLogo";
import SidebarOverlayWrapper from "../../components/atoms/SidebarOverlayWrapper";
import RecruiterStudentNotes from "./components/RecruiterStudentNotes";
import {SIDEBAR_CARD} from "../../utils/dummy";
import RecruiterStudentActivity from "./components/RecruiterStudentActivity";
import RecruiterStudentInfo from "./components/RecruiterStudentInfo";

function mapDispatchToProps(dispatch) {
    return {
        updateCandidate: (candidate) => {
            dispatch({
                type: "UPDATE_CANDIDATE",
                candidate,
            })
        },
        toggleClubPeek: () => {
            dispatch({
                type: "TOGGLE_CLUB_PEEK",
            })
        },
        setClubPeekClub: (club) => {
            dispatch({
                type: "SET_CLUB_PEEK_CLUB",
                user: club,
            })
        }
    }
}

class RecruiterStudentPeek extends PureComponent {
    state = {
        activeTab: 'Activity / Hiring Pipeline',
        opened: false,
        studentEvents: [],
        studentApplications: [],
        companyUserEmails: [],
        rating: 0,
        favorited: false,
        newNote: "",
        notes: [],
        status: "",
        originalStatus: "",
        loading: true,
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (!prevProps.open && this.props.open) {
            this.setState({activeTab: 'Activity / Hiring Pipeline'})
            wait(10).then(() =>
                this.setState({opened: true})
            )
            this.getInfo();
        }

        if (prevProps.open && !this.props.open) {
            this.setState({opened: false})
        }
    }

    getInfo = async () => {
        this.setState({loading: true})
        const [studentApps, studentEvents, student, companyUserEmails] = await Promise.all([
            getUserCompanyApplications(this.props.authUser, this.props.student.id),
            getUserCompanyEvents(this.props.authUser, this.props.student.id),
            getRecruiterStudent(this.props.authUser, this.props.student.id),
            getCompanyUserEmails(this.props.authUser, this.props.student.id)
        ]);
        this.setState({studentApplications: studentApps.applications});
        const companyStudent = student.company_student || {};
        this.setState({
            rating: companyStudent.rating,
            favorited: companyStudent.favorited,
            notes: companyStudent.notes || [],
            status: companyStudent.status,
            studentEvents: studentEvents.events,
            loading: false,
            student,
            companyUserEmails: companyUserEmails.emails,
        });
    }

    close = () => {
        this.setState({opened: false})
        wait(150).then(() => {
            this.props.close()
        })
    }

    deleteNote = async (index) => {
        await deleteNote(this.props.authUser, this.props.student.id, index);
        this.setState({newNote: ""});
        this.getInfo();
    }

    noteChanged = (e) => {
        this.setState({newNote: e.target.value})
    }

    toggleFavoritedState = () => {
        let student = {...this.state.student};
        student.company_student.favorited = !this.state.favorited;
        this.props.updateCandidate(student);

        this.setState({favorited: !this.state.favorited});
        window.analytics.track(`Recruiter Student ${!this.state.favorited ? "Favorited": "Unfavorited"}`)
    }

    addNote = async () => {
        await addNote(this.props.authUser, this.props.student.id, this.state.newNote);
        this.setState({newNote: ""});
        this.getInfo();
        window.analytics.track("Recruiter Student Note Added");
    }

    updateStatus = async (status) => {
        this.setState({status});

        let student = {...this.state.student};
        student.company_student.status = status;
        this.props.updateCandidate(student);

        await updateStatus(this.props.authUser, this.props.student.id, status);
        window.analytics.track("Recruiter Student Status Changed");
    }

    updateRating = async (rating) => {
        this.setState({rating});
        let student = {...this.state.student};
        student.company_student.rating = rating;
        this.props.updateCandidate(student);

        await rateStudent(this.props.authUser, this.props.student.id, rating);
        window.analytics.track("Recruiter Student Rating Updated", {
            studentId: this.props.student.id,
            rating: rating
        })
    }

    openClubPeek = (club, e) => {
        e.stopPropagation();
        this.props.toggleClubPeek();
        this.props.setClubPeekClub(club);
    }

    render() {
        const student = this.state.student || {};
        const education = student.education || [];
        let college = {};
        if (education.length > 0) {
            college = education[0]
        }
        const TABS = [
            'Activity / Hiring Pipeline',
            'Notes',
            student.clubs?.length > 0 && 'Clubs',
            'Experience',
            !!student.resume && 'Resume',
            student.completed_personalization && 'Candidate Info'
        ].filter(Boolean);
        return (
            <>
                <SidebarOverlayWrapper
                    label={'Candidate Details'}
                    open={this.props.open}
                    onClose={this.close}
                >
                    {this.state.loading ?
                        <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                            <Spinner />
                        </div>
                        :
                        <div className='flex flex-1 overflow-hidden'>
                            <StudentPeekHeader student={student} college={college}
                                               authUser={this.props.authUser} updateRating={this.updateRating}
                                               favorited={this.state.favorited} rating={this.state.rating}
                                               toggleFavoritedState={this.toggleFavoritedState} hideStats
                                               className={'pt-0'}
                            />
                            <div className='mt-5'>
                                <SectionTabs
                                    activeTab={this.state.activeTab}
                                    changeTab={this.changeTab}
                                    tabs={TABS}
                                />
                            </div>

                            {this.state.activeTab === 'Activity / Hiring Pipeline' &&
                                <RecruiterStudentActivity
                                    student={student}
                                    status={this.state.status}
                                    updateStatus={this.updateStatus}
                                    close={this.props.close}
                                    history={this.props.history}
                                    studentApplications={this.state.studentApplications}
                                    studentEvents={this.state.studentEvents}
                                    companyUserEmails={this.state.companyUserEmails}
                                />
                            }

                            {this.state.activeTab === 'Notes' &&
                                <RecruiterStudentNotes
                                    notes={this.state.notes}
                                    newNote={this.state.newNote}
                                    deleteNote={this.deleteNote}
                                    noteChanged={this.noteChanged}
                                    addNote={this.addNote}
                                />
                            }

                            {this.state.activeTab === 'Clubs' &&
                                <div className='flex-1 overflow-y-scroll pt-3'>
                                    {student.clubs?.length > 0 &&
                                        <div className={`${SIDEBAR_CARD}`}>
                                            <p className='text-slate-800 text-lg font-semibold mb-3'>
                                                Clubs
                                            </p>
                                            <div className={`flex flex-col gap-5`}>
                                                {student.clubs.map((club, index) =>
                                                    <div
                                                        key={index}
                                                        onClick={(e) => this.openClubPeek(club, e)}
                                                        className={'flex flex-row items-center gap-3 cursor-pointer hover:bg-slate-50 p-2 rounded-xl'}
                                                    >
                                                        <Tooltip
                                                            toggleComponent={
                                                                <ClubLogo
                                                                    clubId={club.id}
                                                                    className={`border border-slate-200`}
                                                                    backupUrl={club.logo_url}
                                                                />
                                                            }
                                                            label={club.name}
                                                        />
                                                        <div>
                                                            <p className='text-md font-semibold text-slate-800'>
                                                                {club.name}
                                                            </p>
                                                            <div className='flex flex-row items-center text-neutral-400 gap-1'>
                                                                <SvgSchool className={'w-4 h-4'}/>
                                                                <p className='body2 m-0'>
                                                                    {club.college}&nbsp;•&nbsp;Est. {club.founded}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {this.state.activeTab === 'Experience' &&
                                <div className='flex-1 overflow-y-scroll pt-3 gap-3 flex flex-col'>
                                    <div className={`${SIDEBAR_CARD}`}>
                                        <p className='text-slate-800 text-lg font-semibold'>
                                            Work
                                        </p>
                                        {student.experiences.length === 0 ?
                                            <div className='flex flex-col items-center py-10'>
                                                <p className='text-xs text-slate-400'>
                                                    {student.first_name} hasn't added past work experience
                                                </p>
                                            </div>
                                            :
                                            <ProfilePageExperienceWidget
                                                experiences={student.experiences}
                                                inPeek
                                                studentProfilePage
                                            />
                                        }
                                    </div>
                                    <div className={`${SIDEBAR_CARD}`}>
                                        <p className='text-slate-800 text-lg font-semibold'>
                                            Education
                                        </p>
                                        {student.education.length === 0 ?
                                            <div className='flex flex-col items-center py-10'>
                                                <p className='text-xs text-slate-400'>
                                                    {student.first_name} hasn't added past education experience
                                                </p>
                                            </div>
                                            :
                                            <ProfilePageEducationWidget
                                                education={student.education}
                                                inPeek
                                                studentProfilePage
                                            />
                                        }
                                    </div>
                                </div>
                            }

                            {this.state.activeTab === 'Resume' &&
                                <div
                                    className='w-full bg-slate-50 flex flex-col items-center p-5 flex-1 overflow-y-scroll'>
                                    <a href={student?.resume}
                                        className='secondary-button body1-bold relative mb-4' style={{minHeight: 30, maxWidth: 300}}
                                        >
                                            Download Resume
                                    </a>
                                    <Document file={{url: student?.resume}}>
                                        <Page pageNumber={1} width={560} className='text-slate-500 text-sm'/>
                                    </Document>
                                </div>
                            }
                            {this.state.activeTab === 'Candidate Info' &&
                                <RecruiterStudentInfo student={student}/>
                            }
                        </div>
                    }
                </SidebarOverlayWrapper>

            </>
        )
    }
}

export default withRouter(connect(null, mapDispatchToProps)(RecruiterStudentPeek));
