import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import OnboardingLeftPane from "../../components/onboarding/OnboardingLeftPane";
import OnboardingHasJobStep from "../../components/onboarding/OnboardingHasJobStep";
import OnboardingActivelyLookingStep from "../../components/onboarding/OnboardingActivelyLookingStep";
import OnboardingJobMatchIntroStep from "../../components/onboarding/OnboardingJobMatchIntroStep";
import OnboardingProfileStep from "../../components/onboarding/OnboardingProfileStep";
import Spinner from "../Spinner";
import { withRouter } from 'react-router';
import { signUp } from "../../api/authentication";
import { firebaseAuth } from "../../api/firebase";
import { signInWithEmailAndPassword } from "@firebase/auth";
import SvgLogo from "../icons/SvgLogo";

function SignupPage({ history }) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [have_job, setHaveJob] = useState(false);
  const [job_status, setJobStatus] = useState("");
  const [referrer, setReferrer] = useState("");
  const [clubId, setClubId] = useState("");
  const [path, setPath] = useState("");
  const [showClubApplicationCta, setClubApplicationCta] = useState(true);
  const [companyId, setCompanyId] = useState(null);
  const [linkType, setLinkType] = useState("event")

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const referrer = queryParameters.get("referred", null);
    const clubIdFromQuery = queryParameters.get("clubId", null);
    const companyId = queryParameters.get("companyId", null);
    const path = queryParameters.get("path", "");
    const invite = queryParameters.get("invite", null);
    const type = queryParameters.get("type", null)

    if (!!path && path.includes("application")) {
      const match = path.match(/club\/([^/]+)/);
      const clubIdFromPath = match ? match[1] : null;
      setClubId(clubIdFromPath);
      setClubApplicationCta(true);
    } else {
      setClubApplicationCta(false);
    }
    if (!!clubIdFromQuery) {
      setClubId(clubIdFromQuery);
    }
    if (!!type) {
      setLinkType(type);
    }

    if (!!companyId) {
      setCompanyId(companyId)
    }
    setPath(path);
    setReferrer(referrer);
  }, []);

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth < 780) {
        history.push(
          !!path
            ? `/authentication/${path}?activeTab=signup`
            : "/authentication?activeTab=signup"
        );
      }
    };

    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);

    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  function goNextStep(nextStep = null) {
    if (!!nextStep) setStep(nextStep);
    else setStep(step + 1);
    setQueryParams(!!nextStep ? nextStep : step + 1);
  }

  const setQueryParams = (currentIndex) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("step", currentIndex);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    history.replace(newUrl);
}

  function goPreviousStep() {
    if (step === 4 && job_status === "Not looking right now") {
      setStep(2);
    } else if (step === 1) {
      history.replace(
        !!referrer ? `/authentication?referred=${referrer}` : "/authentication"
      );
    } else {
      setStep(step - 1);
    }
  }

  const onProfileSubmit = (formData) => {
    signUpPressed(
      formData.email,
      formData.password,
      formData.firstName,
      formData.lastName
    );
    window.analytics.track("User Signed Up");
    window.analytics.track("Onboarding Started");
  };

  const signUpPressed = async (email, password, firstName, lastName) => {
    setLoading(true);
    const queryParameters = new URLSearchParams(window.location.search);
    const path = queryParameters.get("path");
    let invitedClubId = (!!path && !path.includes("application")) ? clubId: null
    await signUp(
      email,
      password,
      firstName,
      lastName,
      referrer,
      invitedClubId,
      have_job,
      job_status
    )
      .catch((err) => {
        setError(err.response.data.error);
        setLoading(false);
        return;
      })
      .then(async (res) => {
        await signInWithEmailAndPassword(firebaseAuth, email, password);
        history.replace(!!path ? `/onboarding/?path=${path}` : "/onboarding");
      });
  };

  if (loading) {
    return (
      <div className={"flex flex-row items-center justify-center my-auto"}>
        <Spinner size={24} />
      </div>
    );
  }
  return (
    <div className="flex flex-row flex-1 overflow-hidden">
      <OnboardingLeftPane step={step} />
      <div className="flex flex-col flex-1 overflow-y-scroll mt-[72px]">
        <SwitchTransition>
          <CSSTransition key={step} timeout={500} classNames="fade-slide">
            <div>
              {/* Sign up flow */}
              {step === 1 && (
                <OnboardingHasJobStep
                  goNextStep={goNextStep}
                  setHaveJob={setHaveJob}
                  goPreviousStep={goPreviousStep}
                  showClubApplicationCta={showClubApplicationCta}
                  clubId={clubId}
                  companyId={companyId}
                  linkType={linkType}
                  showBackButton
                />
              )}
              {step === 2 && (
                <OnboardingActivelyLookingStep
                  goPreviousStep={goPreviousStep}
                  goNextStep={goNextStep}
                  setJobStatus={setJobStatus}
                />
              )}
              {/*Personal information*/}
              {/*Skip this intro screen if the user said they are not looking for a job*/}
              {step === 3 && (
                <OnboardingJobMatchIntroStep
                  goPreviousStep={goPreviousStep}
                  goNextStep={goNextStep}
                />
              )}
              {step === 4 && (
                <OnboardingProfileStep
                  goPreviousStep={goPreviousStep}
                  goNextStep={goNextStep}
                  onProfileSubmit={onProfileSubmit}
                  error={error}
                />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
}

export default withRouter(SignupPage);
