import React, {Component} from "react";
import {connect} from "react-redux";
import SvgMail from "../components/icons/SvgMail";
import Spinner from "../components/Spinner";
import json from "../img/lottie/check.json";
import {emailUsers} from "../api/recruiter/companies";
import {Button} from "../components/atoms/Button";
import ModalComponent from "../components/atoms/modals/ModalComponent";
import PreviewEmailModal from "./modals/PreviewEmailModal";
import WrappedTextInput from "../components/atoms/WrappedTextInput";
import LottieWrapper from "../components/atoms/LottieWrapper";
import RichTextEditor from "../components/atoms/inputs/RichTextEditor";
import {EditorState, ContentState, convertFromRaw} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import SvgEyeOff from "../components/icons/SvgEyeOff";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiter: state.userReducer.user,
        recruiterCompany: state.userReducer.recruiterCompany,
    };
}

const ZERO_STATE = {
    subject: "",
    body: "",
    category: "",
    date: "",
    coverPhoto: "",
    processing: false,
    successModalOpen: false,
    emailPreviewOpen: false,
    editorState: EditorState.createEmpty(),
};

class RecruiterEmailPage extends Component {
    state = ZERO_STATE;

    isEditorContentEmpty = () => {
        const contentState = this.state.editorState.getCurrentContent();
        const plainText = contentState.getPlainText().trim();
        return plainText.length === 0;
    };
    setEditorState = (editorState) => {
        this.setState({editorState});
    };

    closeAllModals = () => {
        this.setState({successModalOpen: false});
        this.setState(ZERO_STATE);
        this.props.history.goBack();
    };

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    initializeEditorState = () => {
        if (this.state.body) {
            try {
                const contentState =
                    typeof value === "string"
                        ? ContentState.createFromText(this.state.body)
                        : convertFromRaw(this.state.body);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({editorState});
            } catch (error) {
                this.setState({editorState: EditorState.createEmpty()});
            }
        } else {
            this.setState({editorState: EditorState.createEmpty()});
        }
    }

    closeAllModals = () => {
        this.setState({successModalOpen: false});
        this.setState(ZERO_STATE);
        this.props.history.goBack();
    }


    save = async () => {
        this.setState({processing: true});
        const props = this.props.location.state;
        let emails = ''
        let userIds = [];
        if (!!props.user?.email) {
            emails = props.user.email
            userIds = [props.user.username]
        } else {
            emails = props.users.map(user => user.email).join(",")
            userIds = props.users.map(user => user.username);
        }

        const contentState = this.state.editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        await emailUsers(this.props.authUser, {
            "subject": this.state.subject,
            "text": htmlContent,
            "user_ids": userIds
        })
        this.setState({successModalOpen: true, processing: false})

    }

    render() {
        const props = this.props.location.state || {};
        return (

            <div className={`flex flex-col flex-1 p-5 gap-3 w-full max-w-[800px] mx-auto`}>
                <div className={'shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'}>
                    <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-5'>
                        <div>
                            <p className='text-slate-800 text-2xl font-semibold'>
                                Send an email
                            </p>
                        </div>
                        <div className="gap-2 flex flex-row gap-3">
                            <Button variant={'secondary'} onClick={this.props.history.goBack}>
                                Cancel
                            </Button>
                            <Button variant={'secondary'} onClick={() => this.setState({emailPreviewOpen: true})}>
                                Preview
                            </Button>
                            <Button
                                icon={SvgMail}
                                onClick={this.save}
                                disabled={this.isEditorContentEmpty() || !this.state.subject}
                                loading={this.state.processing}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                    {/* {props.user && (
                    <p>
                      {props.user.first_name} {props.user.last_name}{" "}
                      <span className="text-slate-500">
                        - {props.user.email}
                      </span>
                    </p>
                  )} */}
                    {/*{props.users &&*/}
                    {/*    <div className="flex flex-row flex-wrap max-h-[136px] overflow-y-scroll">*/}
                    {/*        {props.users.map((user, index) => (*/}
                    {/*            <div key={user.id}>*/}
                    {/*                /!* <TooltipComponent*/}
                    {/*        ToggleComponent={ *!/*/}
                    {/*                <p className="cursor-pointer hover:text-primary">*/}
                    {/*                    {user.first_name} {user.last_name}*/}
                    {/*                    {index !== props.users.length - 1 && ","}&nbsp;*/}
                    {/*                </p>*/}
                    {/*                /!* }*/}
                    {/*        label={user.email}*/}
                    {/*        hover*/}
                    {/*        placement={"bottom"}*/}
                    {/*      /> *!/*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*}*/}
                    <div>
                        <div className='flex flex-col gap-5'>
                            {/* <p className='text-slate-700 mt-3'>
                                Type out an email to send. Your message will be sent from our RecruitU email.
                                Any candidate replies will be sent to your email: <span
                                className='text-primary'>{this.props.recruiter.email}</span>.
                            </p> */}
                            <div className='flex flex-col gap-1'>
                                <div className='text-xs font-medium text-slate-500'>
                                    Recipient{!!props.users && 's'} {!!props.users &&
                                    <span>({props.users.length})</span>}
                                </div>
                                <div>
                                    {props.user &&
                                        <p>
                                            {props.user.first_name} {props.user.last_name}
                                        </p>
                                    }
                                    {props.users &&
                                        <div className='flex flex-row flex-wrap max-h-[84px] overflow-y-scroll'>
                                            {props.users.map((user, index) =>
                                                <div key={user.id}>
                                                    {/* <TooltipComponent
                                                        ToggleComponent={

                                                        }
                                                        label={user.email}
                                                        hover
                                                        placement={'bottom'}
                                                    /> */}
                                                    <p className='cursor-pointer hover:text-primary'>
                                                        {"name" in user ? `${user.name}` : `${user.first_name} ${user.last_name}`}{index !== props.users.length - 1 && ','}&nbsp;
                                                    </p>
                                                </div>
                                            )
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <WrappedTextInput
                                type="text"
                                outerLabel={'Subject'}
                                placeholder={'Add a subject'}
                                className='input-light px-3 w-full'
                                value={this.state.subject}
                                onChange={(e) => this.handleInputChange(e, 'subject')}
                            />
                            <div className='flex flex-col gap-1.5'>
                                <div className='text-xs font-medium text-slate-500'>
                                    Body
                                </div>
                                {/* <textarea
                                    name="body"
                                    value={this.state.body}
                                    onChange={(e) => this.handleInputChange(e, 'body')}
                                    placeholder={'Type your message here...'}
                                    className='input-light text-sm px-3 py-3 base-black-50 w-full min-h-[120px]'
                                /> */}
                                <div>
                                    <RichTextEditor
                                        initialHtml={this.state.body}
                                        editorState={this.state.editorState}
                                        setEditorState={this.setEditorState}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalComponent
                        isOpen={this.state.successModalOpen}
                        backgroundColor={"white"}
                        header={"Email Sent!"}
                        headerToggle
                        size={"sm"}
                        toggle={this.closeAllModals}
                        showScrollBar
                        FooterComponent={
                            <div className="flex flex-row w-full justify-center items-center gap-3">
                                <Button variant={"secondary"} onClick={this.closeAllModals}>
                                    Close
                                </Button>
                            </div>
                        }
                    >
                        <div className="hue-rotate-[120deg]">
                            <LottieWrapper
                                loop={false}
                                json={json}
                                width={100}
                                height={100}
                            />
                        </div>
                    </ModalComponent>
                    <PreviewEmailModal
                        isOpen={this.state.emailPreviewOpen}
                        close={() => this.setState({emailPreviewOpen: false})}
                        text={stateToHTML(this.state.editorState.getCurrentContent())}
                        recruiterName={`${this.props.recruiter.first_name} ${this.props.recruiter.last_name}`}
                        companyName={this.props.recruiterCompany.name}
                        subject={this.state.subject}
                    />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(RecruiterEmailPage);
