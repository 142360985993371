import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getRecruiterEvents } from "../api/recruiter/events";
import RecruiterEventPreviewCard from "./RecruiterEventPreviewCard";
import SvgPlus from "../components/icons/SvgPlus";
import FadeInOnScroll from "../components/atoms/FadeInOnScroll";
import NewJobOrEventCard from "./atoms/NewJobOrEventCard";
import Spinner from "../components/Spinner";
import { Button } from "../components/atoms/Button";
import SectionTabs from "../components/atoms/SectionTabs";
import { DateTime } from "luxon";

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCreateEventModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
            })
        },
    }
}

const mapStateToProps = (state) => {
    return {
        authUser: state.userReducer.authUser,
    };
}

const RecruiterEventsPage = ({ authUser, toggleCreateEventModal }) => {
    const [events, setEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('Upcoming');

    const getFilteredEvents = () => {
        const now = DateTime.now();
        const sortedEvents = [...events].sort((a, b) => {
            return DateTime.fromISO(a.event_date) - DateTime.fromISO(b.event_date);
        });

        if (activeTab === 'Upcoming') {
            return sortedEvents.filter(event => 
                DateTime.fromISO(event.event_date) >= now && event.scope === 'public'
            )
        } else {
            return sortedEvents.filter(event => 
                DateTime.fromISO(event.event_date) < now && (event.scope === 'public' || event.scope === 'expired')
            )
        }
    };

    useEffect(() => {
        const fetchEvents = async () => {
            const res = await getRecruiterEvents(authUser);
            setEvents(res.events);
            setEventsLoading(false);
            window.analytics.page("Recruiter Events Page");
        };
        fetchEvents();
    }, [authUser]);

    const TABS = ['Upcoming', 'Past'];

    return (
        <div className={`flex flex-col flex-1 p-5 gap-8`}>
            <div>
                <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-5'>
                    <div>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            Events
                        </p>
                    </div>
                    <div className='flex flex-row items-center gap-2'>
                        <Button
                            icon={SvgPlus}
                            onClick={toggleCreateEventModal}
                        >
                            New Event
                        </Button>
                    </div>
                </div>
                <div className='mt-5'>
                    <SectionTabs
                        activeTab={activeTab}
                        changeTab={setActiveTab}
                        tabs={TABS}
                        lg
                    />
                </div>
            </div>
            {eventsLoading ?
                <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                    <Spinner />
                </div>
                :
                <div className='grid grid-cols-3 gap-5'>
                    {getFilteredEvents().map((item, index) =>
                        <FadeInOnScroll key={item.id} triggerImmediately
                            delay={(index * 100) + 10}>
                            <RecruiterEventPreviewCard item={item}/>
                        </FadeInOnScroll>
                    )}
                    <NewJobOrEventCard
                        index={getFilteredEvents().length}
                        label={'Post a new event'}
                        onClick={toggleCreateEventModal}
                    />
                </div>
            }
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterEventsPage);
