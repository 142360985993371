import * as Dialog from '@radix-ui/react-dialog';
import SvgClose from "../../icons/SvgClose";
import React, { PureComponent } from "react";
import SvgAddCircle from "../../icons/SvgAddCircle";
import Dropzone from 'react-dropzone';
import SvgEdit from "../../icons/SvgEdit";
import MultiSelectComponent from '../MultiSelectComponent';
import { connect } from "react-redux";
import { uploadDoc } from "../../../api/firebase";
import { editCompany } from "../../../api/student/companies";
import RichTextEditor from "../inputs/RichTextEditor";
import { EditorState, ContentState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import ModalComponent from './ModalComponent';
import {wait} from "@testing-library/user-event/dist/utils";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        constants: state.userReducer.constants
    };
}
class EditCompanyModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            logo_url: "",
            ranking: null,
            tags: [],
            secondaryTags: [],
            editorState: EditorState.createEmpty(),
        };
    }

    newTagChanged = e => this.setState({newTag: e.target.value});
    newSecondaryTagChanged = e => this.setState({newSecondaryTag: e.target.value});

    updateTags = (tag) => {
        if (tag === null) {
            this.setState({tags: []});
            return
        }

        let newTags = [...this.state.tags];
        if (this.state.tags.includes(tag)) {
            const index = this.state.tags.indexOf(tag);
            newTags.splice(index, 1);
        } else {
            newTags.push(tag);
        }
        this.setState({tags: newTags});
    }

    updateSecondaryTags = (tag) => {
        if (tag === null) {
            this.setState({secondaryTags: []});
            return
        }

        let newTags = [...this.state.secondaryTags];
        if (this.state.secondaryTags.includes(tag)) {
            const index = this.state.secondaryTags.indexOf(tag);
            newTags.splice(index, 1);
        } else {
            newTags.push(tag);
        }
        this.setState({secondaryTags: newTags});
    }

    handleInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    componentDidMount = () => {
        this.setInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setInfo();
            })
        }
    }

    setInfo = () => {
        const company = this.props.company || {};
        this.setState({
            logo_url: company.logo_url,
            name: company.name,
            sector: company.sector,
            description: company.description,
            ranking: company.ranking,
            numEmployees: company.num_employees,
            tags: company.specialties,
            secondaryTags: company.secondary_specialties,
            revenue: company.revenue,
            aum: company.aum,
            ceo: company.ceo,
            classSize: company.class_size,
            cities: company.cities,
            headquarters: company.headquarters,
        })

        if (company.description) {
            try {
                const contentState = typeof value === 'string'
                    ? ContentState.createFromText(company.description)
                    : convertFromRaw(company.description);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editorState });
            } catch (error) {
                this.setState({ editorState: EditorState.createEmpty() });
            }
        } else {
            this.setState({ editorState: EditorState.createEmpty() });

        }
    }

    setEditorState = (editorState) => {
        this.setState({editorState});
    }

    updateCompany = async () => {
        const contentState = this.state.editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);

        await editCompany(this.props.authUser, this.props.company.id, {
            logo_url: this.state.logo_url,
            name: this.state.name,
            sector: this.state.sector,
            description: htmlContent,
            ranking: Number(this.state.ranking),
            num_employees: this.state.numEmployees,
            specialties: this.state.tags,
            secondary_specialties: this.state.secondaryTags,
            revenue: this.state.revenue,
            aum: this.state.aum,
            ceo: this.state.ceo,
            class_size: this.state.classSize,
            cities: this.state.cities,
            headquarters: this.state.headquarters,
        });
        this.props.fetchCompany();
    }

    onDrop = async (acceptedFiles) => {
        const url = await uploadDoc("companies", this.props.company.id, acceptedFiles[0]);
        this.setState({logo_url: url});
    }

    render() {
        const company = this.props.company;
        const saveEnabled = true;//this.state.logo_url !== company.logo_url || this.state.name !== company.name || this.state.sector !== company.sector || this.state.description !== company.description || this.state.ranking !== company.ranking || company.num_employees !== this.state.numEmployees;
        const tags = this.state.tags || [];
        const secondaryTags = this.state.secondaryTags || [];
        const INDUSTRY_TAGS = this.props.constants.industry_tags.map(tag => ({title: tag, value: tag})); 
        const SECONDARY_TAGS = this.props.constants.secondary_tags.map(tag => ({title: tag, value: tag})); 
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit Company'}
                headerToggle
                size={'xl'}
                id={'edit-company-modal'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <div style={{display: 'flex', marginTop: 25, justifyContent: 'flex-end'}} className='gap-3'>
                            <button className="secondary-button body1-bold" onClick={this.props.closeModal}>
                                Cancel
                            </button>
                            <button className={`primary-button body1-bold ${!saveEnabled && 'opacity-50 cursor-not-allowed'}`} 
                                    onClick={() => this.updateCompany()} 
                                    disabled={!saveEnabled}>
                                    Save
                            </button>
                        </div>
                    </div>
                }
                footerAlignment={'right'}
            >
                        <div id='edit-company-modal'>
                            <div className='flex flex-col gap-5 mt-5'>
                                <div className='flex flex-row items-center gap-3'>
                                    {!this.state.logo_url ?
                                        <div
                                            className='br-12 bg-neutral-200 flex flex-col items-center border border-neutral-200'
                                            style={{width: 80, height: 80}}/>
                                        :
                                        <img alt={'company'} src={this.state.logo_url}
                                             className='br-12 object-contain border bg-white border-neutral-200 '
                                             style={{width: 80, height: 80}}/>
                                    }

                                    <Dropzone onDrop={this.onDrop}>
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps()} className='cursor-pointer'>
                                                <input {...getInputProps()} />
                                                {/* <div
                                                    className='w-full flex flex-row items-center justify-center rounded-xl h-26 border border-neutral-300 border-dashed p-6 text-neutral-400 hover:bg-neutral-100'>
                                                    <p className='body3 ml-2'>
                                                        hi
                                                    </p>
                                                </div> */}
                                                {!this.state.logo_url ?
                                                    <div
                                                        className='text-indigo-500 cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center gap-2'>
                                                        <SvgAddCircle className={'w-4 h-4'}/> Add Company Logo
                                                    </div>
                                                    :
                                                    <div
                                                        className='text-indigo-500 cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center gap-2'>
                                                        <SvgEdit className={'w-4 h-4'}/> Edit Company Logo
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </Dropzone>

                                </div>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Name
                                    </p>
                                    <input
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.handleInputChange}
                                        placeholder="Club name"
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className='flex flex-row gap-3'>
                                    <div className="col-plain flex-1">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Industry
                                        </p>
                                        <input
                                            name="sector"
                                            value={this.state.sector}
                                            onChange={this.handleInputChange}
                                            placeholder="Industry"
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-row gap-3'>
                                    <div className="col-plain flex-1">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Number of Employees
                                        </p>
                                        <input
                                            name="numEmployees"
                                            value={this.state.numEmployees}
                                            onChange={this.handleInputChange}
                                            placeholder="Number of Employees"
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-row gap-3'>
                                    <div className="col-plain flex-1">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Ranking
                                        </p>
                                        <input
                                            name="ranking"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            value={this.state.ranking}
                                            onChange={this.handleInputChange}
                                            placeholder="Ranking (e.g. 5)"
                                            className='input-light px-3 body2 base-black-50 w-full'
                                        />
                                    </div>
                                </div>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        About
                                    </p>
                                    {/* <textarea
                                        name="description"
                                        value={this.state.description}
                                        onChange={this.handleInputChange}
                                        rows="4"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Write your thoughts here..."
                                    /> */}
                                    <div>
                                        <RichTextEditor initialHtml={this.state.description} editorState={this.state.editorState} setEditorState={this.setEditorState}/>
                                    </div>
                                </div>
                                <div className="col-plain flex-1">
                                <label className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                                Tags
                            </label>
                            <div className="col-plain mt-2">
                                <div className='flex flex-row items-center gap-2'>
                                    <MultiSelectComponent
                                        className={'flex-[0.5]'}
                                        outerLabel={'Tags'}
                                        label={'...'}
                                        value={tags}
                                        light
                                        setValue={this.updateTags}
                                        clearFilter={() => this.updateTags(null)}
                                        options={INDUSTRY_TAGS}
                                        node={document.getElementById('edit-company-modal')}
                                    />
                                </div>
                            </div>
                            <div className="col-plain mt-2 overflow-y-scrolls">
                                <div className='flex flex-row items-center gap-2'>
                                    <MultiSelectComponent
                                        className={'flex-[0.5]'}
                                        outerLabel={'Secondary Tags'}
                                        label={'...'}
                                        value={secondaryTags}
                                        light
                                        setValue={this.updateSecondaryTags}
                                        clearFilter={() => this.updateSecondaryTags(null)}
                                        options={SECONDARY_TAGS}
                                        node={document.getElementById('edit-company-modal')}
                                        scrollable
                                    />
                                </div>
                                </div>
                                </div>
                            </div>
                            <div className='flex flex-row gap-3 mt-1'>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Revenue
                                    </p>
                                    <input
                                        name="revenue"
                                        value={this.state.revenue}
                                        onChange={this.handleInputChange}
                                        placeholder="Revenue"
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-row gap-3 mt-1'>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        AUM
                                    </p>
                                    <input
                                        name="aum"
                                        value={this.state.aum}
                                        onChange={this.handleInputChange}
                                        placeholder="AUM"
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-row gap-3 mt-1'>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        CEO
                                    </p>
                                    <input
                                        name="ceo"
                                        value={this.state.ceo}
                                        onChange={this.handleInputChange}
                                        placeholder="CEO"
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-row gap-3 mt-1'>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Class Size
                                    </p>
                                    <input
                                        name="classSize"
                                        value={this.state.classSize}
                                        onChange={this.handleInputChange}
                                        placeholder="Class Size"
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-row gap-3 mt-1'>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Headquarters
                                    </p>
                                    <input
                                        name="headquarters"
                                        value={this.state.headquarters}
                                        onChange={this.handleInputChange}
                                        placeholder="Headquarters"
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                        </div>
                        
                </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(EditCompanyModal);
