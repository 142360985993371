import React, {Component} from "react";
import {connect} from "react-redux";
import HorizontalDivider from "../components/atoms/HorizontalDivider";
import CollapseComponent from "../components/atoms/CollapseComponent";
import {Button} from "../components/atoms/Button";
import GoBack from "../components/atoms/GoBack";
import {getCompanyEmails} from "../api/recruiter/companies";
import RecruiterDownloadEmailsCsv from "./RecruiterDownloadEmailsCsv";

function formatDividerDate(inputDate) {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const [year, month, day] = inputDate.split('-');
    const monthAbbr = months[parseInt(month, 10) - 1];
    return `${monthAbbr} ${parseInt(day, 10)}, ${year}`;
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiter: state.userReducer.user,
    };
}


class EmailHistoryPage extends Component {
    state = {
        emails: [],
        openEmailIds: {},
    };

    toggleEmailCollapse = (id) => {
        this.setState((prevState) => ({
            openEmailIds: {
                ...prevState.openEmailIds,
                [id]: !prevState.openEmailIds[id],
            },
        }));
    };

    componentDidMount = () => {
        this.getEmailHistory();
    }

    getEmailHistory = async () => {
        const res = await getCompanyEmails(this.props.authUser);
        this.setState({emails: res.emails})
    }

    prepareCSVData = () => {
        return this.state.emails.map(email => ({
            sender: email.entity,
            subject: email.subject,
            message: email.text.replace(/<[^>]+>/g, ''),
            recipients: (email.emails || []).join(', ')
        }));
    };

    renderEmailItem = (email) => {
        const isOpen = this.state.openEmailIds[email.id];
        const recepients = email.emails || [];
        const date = new Date(email.created_at);
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
        return (
            <div key={email.id}
                 className="flex flex-col bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl mb-2">
                <p className="text-xs text-slate-500">{email.entity}</p>
                <div className="flex flex-row justify-between gap-8">
                    <div className={'flex-1'}>
                        <p className={'text-xs text-slate-500'}>
                            Sent {formattedDate}
                        </p>
                        <p className="text-lg font-semibold text-slate-800">{email.subject}</p>
                    </div>
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => this.toggleEmailCollapse(email.id)}
                        className="mt-2"
                    >
                        {isOpen ? "Show less" : "Show email"}
                    </Button>
                </div>
                <CollapseComponent isOpen={!!isOpen}>
                    <div className="text-slate-700 mt-3 mb-5">
                        <div className="DraftEditor-root">
                            <div
                                className="public-DraftEditor-content"
                                dangerouslySetInnerHTML={{__html: email.text}}
                            />
                        </div>
                    </div>
                </CollapseComponent>
                <HorizontalDivider className="bg-slate-200 my-3"/>
                <p className="text-sm text-slate-700">
                    Recipients
                    ({recepients.length}): {recepients.length < 10 ? recepients.join(", ") : `${recepients.slice(0, 10).join(", ")} + ${recepients.length - 10} more`}
                </p>
            </div>
        );
    };

    renderEmailsWithDividers = () => {
        const {emails} = this.state;
        const groupedEmails = emails.reduce((acc, email) => {
            const dateKey = email.created_at.split("T")[0];
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(email);
            return acc;
        }, {});

        return Object.keys(groupedEmails).map((dateKey) => {
            return (
                <div key={dateKey} className="mb-6">
                    <div className={'flex flex-row items-center mb-3 -mx-4'}>
                        <h3 className="text-md font-bold text-slate-700">
                            Sent {formatDividerDate(dateKey)}
                        </h3>
                        <div className={'flex-1 ml-2 h-[2px] bg-slate-200'}/>
                    </div>
                    {groupedEmails[dateKey].map(this.renderEmailItem)}
                </div>
            );
        });
    };

    render() {
        const {emails} = this.state;
        return (
            <div className="flex flex-col p-6 w-full max-w-[800px] mx-auto">
                <div className={'sticky top-0 bg-white pt-3'}>
                    <GoBack/>
                    <div className="flex flex-row justify-between items-center mb-6">
                        <p className="text-2xl font-semibold mt-2 text-slate-800">Email History</p>
                        {emails.length > 0 && (
                            <RecruiterDownloadEmailsCsv emails={emails}/>
                        )}
                    </div>
                </div>
                <div className={'mx-4'}>
                    {emails.length > 0 ? (
                        this.renderEmailsWithDividers()
                    ) : (
                        <p className="text-sm text-slate-600">No emails sent yet.</p>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(EmailHistoryPage);
