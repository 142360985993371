import React, {Component} from "react";
import SvgPlus from "../../components/icons/SvgPlus";
import ClubPageAddPocModal from "./modals/ClubPageAddPocModal";
import SvgRemove from "../icons/SvgRemove";
import {connect} from "react-redux";
import { editClub } from "../../api/student/clubs";
import SvgVisibility from "../icons/SvgVisibility";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import SvgEyeOff from "../icons/SvgEyeOff";
import Tooltip from "../atoms/modals/Tooltip";
import TagComponent from "../atoms/TagComponent";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class PointsOfContactWidget extends Component {
    state = {
        addPocModal: false
    }

    openAddPocModal = () => {
        this.setState({addPocModal: true})
    }

    closeAddPocModal = () => {
        this.setState({addPocModal: false})
    }

    remove = async (poc) => {
        const pocs = [...this.props.club.points_of_contact];
        const index = pocs.indexOf(poc);
        pocs.splice(index, 1);

        await editClub(this.props.authUser, this.props.club.id, {points_of_contact: pocs});
        this.props.getClubInfo();
    }

    render() {
        const club = this.props.club || {};
        const pocs = club.points_of_contact || [];
        const hidden = this.props.hidden || [];
        return (
            <div
                className={`bg-white shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl mt-5 ${hidden.includes("points_of_contact") && 'opacity-50'}`}>
                <div className='flex flex-row items-center gap-2 mb-2'>
                    <p className='text-xl font-semibold text-slate-800'>
                        Points of Contact
                    </p>
                    {pocs.length !== 0 && <SvgCheckCircle className={'w-5 h-5 text-primary'}/>}
                    {!this.props.loading && this.props.isAdmin &&
                        <>
                            <button
                                onClick={this.openAddPocModal}
                                className='text-slate-500 hover:text-primary cursor-pointer'>
                                <SvgPlus className={'w-4 h-4'}/>
                            </button>
                            <Tooltip
                                toggleComponent={
                                    <button
                                        onClick={() => this.props.hide("points_of_contact")}
                                        className='text-slate-500 hover:text-primary cursor-pointer'>
                                        {hidden.includes("points_of_contact") ?
                                            <SvgVisibility className={'w-4 h-4'}/>
                                            :
                                            <SvgEyeOff className={'w-4 h-4'}/>
                                        }
                                    </button>
                                }
                                label={`${hidden.includes("points_of_contact") ? "Show" : "Hide"} this section when recruiters are viewing your club.`}
                            />
                        </>
                    }
                </div>
                <div className='flex flex-col gap-8'>
                    {pocs.length === 0 &&
                        <p className='text-slate-500'>
                            No points of contact listed yet
                        </p>
                    }
                    {!this.props.hideEmails &&
                        <div className='flex flex-col items-start gap-10 flex-wrap gap-y-2 mt-2'>
                            {pocs.map(item =>
                                <div className='flex flex-row items-center gap-3' key={item.email}>
                                    <div>
                                        <p className='text-slate-800 text-lg font-semibold'>
                                            {!!item.name ? item.name : item.email}
                                        </p>
                                        <p className='text-slate-500 text-sm'>
                                            {!!item.name ? item.email : null}
                                        </p>
                                    </div>
                                    <div>
                                        {item.admin ?
                                            <TagComponent
                                                label={"Admin"}
                                                type={''}
                                            />
                                            : null}
                                    </div>
                                    {this.props.isAdmin ?
                                        <div className="flex flex-row items-center" onClick={() => this.remove(item)}>
                                            <SvgRemove className={'w-5 h-5 text-neutral-400 hover:text-red-500 cursor-pointer'}/>
                                        </div>
                                    : null}
                                </div>
                            )}
                        </div>
                    }
                </div>


                <ClubPageAddPocModal
                    isOpen={this.state.addPocModal}
                    closeModal={this.closeAddPocModal}
                    club={this.props.club}
                    getClubInfo={this.props.getClubInfo}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(PointsOfContactWidget);
