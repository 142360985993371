import React, {PureComponent} from "react";
import Tooltip from "../atoms/modals/Tooltip";
import SvgUnclaimed from "../icons/SvgUnclaimed";
import SvgClaimed from "../icons/SvgClaimed";
import Spinner from "../Spinner";
import SvgEmailSent from "../icons/SvgEmailSent";
import {sendBumpEmail} from "../../api/student/users";
import {Link} from "react-router-dom"
import {removeClubMember, assignClubAdmin} from "../../api/student/admin";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import SvgMail from "../icons/SvgMail";
import { editClub } from "../../api/student/clubs";
import SvgPeopleFeather from "../icons/SvgPeopleFeather";
import SvgRemove from "../icons/SvgRemove";
import DropdownComponent from "../atoms/DropdownComponent";
import SvgMoreVertical from "../icons/SvgMoreVertical";
import SvgLock from "../icons/SvgLock";
import SvgPlus from "../icons/SvgPlus";
import SvgEdit from "../icons/SvgEdit";
import {connect} from "react-redux";
import TagComponent from "../atoms/TagComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import { createNewAdmin } from "../../api/clubAdmin/clubs";
import {removeClubAdmin} from "../../api/student/admin";


function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}

class ClubPageMemberListItem extends PureComponent {
    state = {
        hovering: false,
        bumped: false,
        sendingBump: false
    }

    onMouseEnter = () => {
        this.setState({hovering: true});
    }

    onMouseLeave = () => {
        this.setState({hovering: false});
    }

    sendBump = async () => {
        this.setState({sendingBump: true});
        await sendBumpEmail(this.props.authUser, this.props.club.id, this.props.item.username);
        this.setState({bumped: true, sendingBump: false});
    }

    removeMember = async () => {
        await removeClubMember(this.props.authUser, this.props.club.id, this.props.item.username);
        wait(1000).then(() =>{
            this.props.getClubInfo();
        })

    }

    assignAdmin = async () => {
        await assignClubAdmin(this.props.authUser, this.props.club.id, this.props.item.username);
        this.props.getClubInfo();
    }

    openStudentPeek = () => {
        this.props.toggleStudentPeek();
        this.props.setStudentPeekStudent(this.props.item);
    }

    addRemovePoc = async () => {
        const club = this.props.club || {};
        const pocEmails = club.points_of_contact.map(poc => poc.email);
        const pocs = [...this.props.club.points_of_contact];

        const member = this.props.item
        if (pocEmails.includes(member.email)) {
            const index = pocEmails.indexOf(member.email);
            pocs.splice(index, 1);
        } else {
            pocs.push({
                email: member.email,
                name: `${member.first_name} ${member.last_name}`,
                username: member.username,
            })
        }
        
        await editClub(this.props.authUser, this.props.club.id, {points_of_contact: pocs});
        this.props.getClubInfo();
    }

    addRemoveAdmin = async (member) => {
        const club = this.props.club || {};
        if (club.admin.includes(member.username)) {
            await removeClubAdmin(this.props.authUser, club.id, member.username)
        } else {
            await createNewAdmin(this.props.authUser, club.id, member.email);
        }
        this.props.getClubInfo();
    }

    render() {
        const member = this.props.item;
        const profilePic = member.profile_pic_url ? member.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
        const leadership = this.props.leadership || {};
        const position = leadership[member.username];
        const club = this.props.club || {};
        const pocEmails = club.points_of_contact.map(poc => poc.email);
        const TAGS = {
            "Actively looking for a job": {title: "Searching", type: "rank"},
            "Open to opportunities": {title: "Open", type: "industry"},
            "Not looking right now": {title: "Closed", type: "random"}
        }
        return (
            <div className={`row-ac-jb py-4 border-b border-b-neutral-100 last:border-none`}
                 onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                <div className='flex flex-row items-center gap-1' style={{flex: .30}}>
                    <img className='bg-base-white-20 br-32 border border-neutral-200' alt={"profile pic"}
                        onError={() => this.setState({profileError: true})}
                        style={{width: 28, height: 28}} src={!this.state.profileError ? profilePic : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8"}/>
                    <div className='flex flex-row items-center flex-wrap'>
                        <p className='body2-bold base-black-70'>
                            {member.first_name} {member.last_name}
                        </p>
                        {member.linkedin &&
                            <a href={member.linkedin}
                               target={"_blank"}>

                                <SvgLinkedIn className={'base-black-30 h-base-black-70 pointer w-4 h-4'}/>
                            </a>
                        }
                        {this.props.adminIds.includes(this.props.club.id) ?
                            <>
                                {member.claimed ?
                                    <div className={'flex flex-row gap-2 items-center'}>
                                        <Tooltip
                                            toggleComponent={
                                                <div className='flex flex-row items-center gap-1 body3 text-green-500'>
                                                    <SvgClaimed className={'w-4 h-4'}/>
                                                </div>
                                            }
                                            label={'Profile is claimed'}
                                        />
                                    </div>
                                    :
                                    <>
                                        {this.state.bumped ?
                                            <div className={'flex flex-row gap-2 items-center'} onClick={this.sendBump}>
                                                <Tooltip
                                                    toggleComponent={
                                                        <div
                                                            className='flex flex-row items-center gap-1 body3 text-green-500'>
                                                            <SvgEmailSent className={'w-4 h-4'}/>
                                                        </div>
                                                    }
                                                    label={`Email sent to ${member.first_name}!`}
                                                />
                                            </div>
                                            :
                                            <div className={'flex flex-row gap-1 items-center'}>
                                                <Tooltip
                                                    toggleComponent={
                                                        <div
                                                            className='flex flex-row items-center gap-1 body3 text-orange-400 cursor-pointer'>
                                                            {this.state.sendingBump ?
                                                                <div
                                                                    className='w-4 h-4 flex flex-col items-center justify-center'>
                                                                    <Spinner />
                                                                </div>
                                                                :
                                                                <SvgUnclaimed className={'w-4 h-4'}/>
                                                            }
                                                        </div>
                                                    }
                                                    content={
                                                        <button onClick={this.sendBump}
                                                                className='secondary-pill-sm bg-white border hover:bg-white hover:bg-opacity-80'
                                                                style={{color: !this.state.sendingBump && '#4848F2'}}>
                                                            {this.state.sendingBump ? 'Sending...' : 'Remind'}
                                                        </button>
                                                    }
                                                    label={`Remind ${member.first_name} to claim their account`}
                                                />
                                            </div>
                                        }
                                    </>
                                }
                            </>
                            : null}
                            {pocEmails.includes(member.email) &&
                                <Tooltip
                                    toggleComponent={
                                        <div
                                            className='flex flex-row items-center gap-1 body3 text-purple-400'>
                                            <SvgPeopleFeather className={'w-4 h-4'}/>
                                        </div>
                                    }
                                    label={`Member is a point of contact`}
                                />
                        }
                    </div>
                </div>
                <div className='row-ac primary 2xl:block' style={{flex: .25}}>
                    <p className={`body4 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {member.email}
                    </p>
                </div>
                {/* <div className='row-ac primary block 2xl:hidden' style={{flex: .2}}>
                    <Tooltip
                        toggleComponent={
                            <div className='flex flex-col items-center'>
                                <SvgMail className={'w-4 h-4 text-slate-500'}/>
                            </div>
                        }
                        label={`${member.email}`}
                    />
                </div> */}
                <div style={{flex: .15}}>
                    <p className={`body3 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {position || 'Member'}
                    </p>
                </div>
                <div style={{flex: .15}}>
                    <p className={`body3 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {member.grade || '--'}
                    </p>
                </div>
                <div className='row-ac primary hidden lg:block' style={{flex: .3}}>
                    <p className={`body4 ${!member.claimed ? 'base-black-50' : 'base-black-100'}`}>
                        {member.major.join(", ") || '--'}
                    </p>
                </div>
                <div className='row-ac primary hidden lg:block ml-2' style={{flex: .1}}>
                    <p className={`body3 base-black-100`}>
                        {this.props.clubAdmin && member.claimed ? (member.gpa || '--') : '--'}
                    </p>
                </div>
                <div className='row-ac primary hidden lg:block ml-2' style={{flex: .15}}>
                        {this.props.clubAdmin && member.claimed && !!member.job_status ?
                            <div className="flex items-center">
                                <TagComponent
                                    label={TAGS[member.job_status].title}
                                    type={TAGS[member.job_status].type}
                                />
                            </div>
                        :
                            <p>--</p>
                        }
                </div>
                <div className={'flex flex-[0.15] flex-row gap-2 justify-end'}>
                    {member.claimed &&
                        <div className={`px-2 py-1.5 rounded-lg bg-primary-light primary body3-bold pointer`} onClick={this.openStudentPeek}>
                            {this.props.adminIds.includes(this.props.club.id) ? "View Progress" : "View"}
                        </div>
                    }
                    {this.props.adminIds.includes(this.props.club.id) &&
                        <DropdownComponent
                            ToggleComponent={
                                <div
                                    className='flex flex-col items-center mt-[8px] justify-center text-slate-500 hover:text-slate-800 cursor-pointer'>
                                    <SvgMoreVertical className={'w-4 h-4'}/>
                                </div>
                            }
                            value={null}
                            isControlled={false}
                            setValue={() => null}
                            options={
                                [
                                    {
                                        title: 'Edit position',
                                        icon: SvgEdit,
                                        onClick: () => this.props.openPositionModal(member),
                                    },
                                    {
                                        title: pocEmails.includes(member.email) ? 'Remove as point of contact' : 'Assign as point of contact',
                                        icon: SvgPeopleFeather,
                                        onClick: this.addRemovePoc,
                                    },
                                    {
                                        title: club.admin.includes(member.username) ? 'Remove as admin' : 'Assign as admin',
                                        icon: club.admin.includes(member.username) ? SvgRemove : SvgPlus,
                                        onClick: () => this.addRemoveAdmin(member),
                                        color: club.admin.includes(member.username) ? 'red' : ''
                                    },
                                    {
                                        title: 'Remove this member',
                                        icon: SvgRemove,
                                        onClick: this.removeMember,
                                        color: 'red'
                                    },
                                    
                                ]
                            }
                            direction="bottom"
                            light
                        />
                    }
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(ClubPageMemberListItem);
