import React from 'react';
import { DateTime } from 'luxon';

const RecruiterStudentPeekEmailItem = ({ email }) => {
    return (
        <div className='flex flex-col bg-slate-50 rounded-lg p-3'>
            <div className='flex flex-row gap-4 items-center'>
                <div className='flex flex-col items-center rounded-lg border-neutral-200 w-11 bg-white border overflow-hidden'>
                    <p className='body4-bold uppercase p-0.5 px-2 bg-neutral-100 w-full text-center'>
                        {DateTime.fromISO(email.created_at).toFormat("MMM")}
                    </p>
                    <p className='body2-bold p-0.5'>
                        {DateTime.fromISO(email.created_at).toFormat("d")}
                    </p>
                </div>
                <div>
                    <p className='text-sm text-slate-500'>
                        Sent by: {email.recruiter?.first_name} {email.recruiter?.last_name}
                    </p>
                    <p className='text-md font-semibold text-slate-800'>
                        {email.subject || <span className={'opacity-50'}>No subject</span>}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RecruiterStudentPeekEmailItem;

