export const ONBOARDING_STEPS = {
    EMAIL_VERIFICATION: 4,
    EDUCATION: 5,
    RESUME: 6,
    LINKEDIN: 7,
    WORK: 8,
    CITIES: 9,
    INDUSTRIES: 10,
    COMPANIES: 11,
    MATCHES: 12,
};

export const getFlowSteps = (isMobile, have_job, job_status) => {
    // Helper function to remove resume step if on mobile
    const filterMobileSteps = (steps) => {
        return isMobile
            ? steps.filter((step) => step !== ONBOARDING_STEPS.RESUME)
            : steps;
    };

    // Determine the appropriate flow based on job status and whether they have a job
    let selectedFlow;

    if (job_status === "Not looking right now") {
        if (have_job) {
            // Has job, not looking
            selectedFlow = [
                ONBOARDING_STEPS.EDUCATION,
                ONBOARDING_STEPS.RESUME,
                ONBOARDING_STEPS.LINKEDIN,
                ONBOARDING_STEPS.WORK,
            ];
        } else {
            // No job, not looking
            selectedFlow = [
                ONBOARDING_STEPS.EDUCATION,
                ONBOARDING_STEPS.RESUME,
                ONBOARDING_STEPS.LINKEDIN,
            ];
        }
    } else {
        // Either "Actively looking for a job" or "Open to opportunities"
        if (have_job) {
            // Has job and looking/open
            selectedFlow = [
                ONBOARDING_STEPS.EDUCATION,
                ONBOARDING_STEPS.RESUME,
                ONBOARDING_STEPS.LINKEDIN,
                ONBOARDING_STEPS.WORK,
                ONBOARDING_STEPS.CITIES,
                ONBOARDING_STEPS.INDUSTRIES,
                ONBOARDING_STEPS.COMPANIES,
                ONBOARDING_STEPS.MATCHES,
            ];
        } else {
            // No job and looking/open
            selectedFlow = [
                ONBOARDING_STEPS.EDUCATION,
                ONBOARDING_STEPS.RESUME,
                ONBOARDING_STEPS.LINKEDIN,
                ONBOARDING_STEPS.CITIES,
                ONBOARDING_STEPS.INDUSTRIES,
                ONBOARDING_STEPS.COMPANIES,
                ONBOARDING_STEPS.MATCHES,
            ];
        }
    }

    return filterMobileSteps(selectedFlow);
};
