import React, {useState} from "react";
import {Button} from "../components/atoms/Button";
import SvgMoreVertical from "../components/icons/SvgMoreVertical";
import {deleteList, editList} from "../api/recruiter/lists";
import EditListModal from "./modals/EditListModal";
import SvgEdit from "../components/icons/SvgEdit";
import MessageStudentFlow from "./MessageStudentFlow";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import {useHistory} from "react-router-dom";
import SvgStars from "../components/icons/SvgStars";

function RecruiterListGroup({list, authUser, toggleCreateEventModal, toggleCreateJobModal, getLists, type}) {
    const [isEditing, setIsEditing] = useState(false)
    const [IsEditClubListModalOpen, setIsEditClubListModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const history = useHistory();

    const remove = async (id) => {
        await editList(authUser, list.id, type, {
            [`${type}_ids`]: [id]
        })
        getLists();
    }

    const deleteClubList = async () => {
        await deleteList(authUser, list.id, type);
        getLists();
    }

    const navigateToFullListPage = () => {
        history.push(`/list/${type}/${list.id}`);
    }

    return (
        <div
            className={
                `
            bg-white 
            shadow-lg border border-slate-200 rounded-xl overflow-hidden`
            }
        >
            <div onClick={navigateToFullListPage}
                 className='flex hover:bg-slate-50 p-3 pr-1 cursor-pointer company-collapse flex-row items-center justify-between'>
                <div className='flex flex-row items-center gap-2'>
                    <p className='text-slate-700 text-lg font-bold'>
                        {list.name} {type !== "smart" && <span className='text-slate-500'>({list[`${type}s`].length})</span>}
                    </p>
                    {type === 'smart' &&
                        <SvgStars className={'text-primary'}/>
                    }
                </div>
                <div className='flex flex-row items-center gap-5'>
                    <>
                        {/* {type === "user" && <MessageStudentFlow users={[]} buttonVariant={'secondary'}/>} */}
                        {isEditing ?
                            <div className="mr-8">
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setIsEditing(false)
                                    }}
                                    className="mr-8"
                                    variant={'secondary'}>
                                    {type === 'user' ? 'Cancel' : 'Finish editing'}
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setIsEditClubListModalOpen(true)
                                    }}
                                    className="mr-8"
                                    variant={'secondary'}>
                                    Edit
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setConfirmationModalOpen(true);
                                    }}
                                    variant={'destructive'}>
                                    Delete list
                                </Button>
                            </div>

                            :
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsEditing(true)
                                }}
                                icon={SvgMoreVertical} size={'icon'}
                                variant={'secondary'}/>
                        }
                    </>
                    {!isEditing &&
                        <div className='flex flex-row items-center gap-2'>
                            <div className='company-collapse__button body2-bold mr-12'>
                                View List
                            </div>
                        </div>
                    }
                </div>
            </div>
            <EditListModal
                isOpen={IsEditClubListModalOpen}
                currentList={list}
                type={type}
                closeModal={() => setIsEditClubListModalOpen(false)}
                getLists={getLists}
            />
            <ConfirmationModal
                isOpen={confirmationModalOpen}
                closeModal={() => setConfirmationModalOpen(false)}
                confirmText={'Delete'}
                title={'Are you sure?'}
                onConfirm={deleteClubList}
                description={'Are you sure you want to delete this list? This action cannot be undone.'}
            />
        </div>
    )
}

export default RecruiterListGroup
