import React, { useMemo, useState } from "react";
import { DayPicker } from "react-day-picker";
import { DateTime } from "luxon";
import { Button } from "../components/atoms/Button";
import ModalComponent from "../components/atoms/modals/ModalComponent";
import * as Popover from '@radix-ui/react-popover';
import SvgDownload from "../components/icons/SvgDownload";

export default function RecruiterDownloadEmailsCsv({ emails }) {
    const [isOpen, setIsOpen] = useState(false);
    const [range, setRange] = useState({ from: undefined, to: undefined });


    const csvData = useMemo(() => {
        let filteredEmails = [];

        if (range.from && range.to) {
            filteredEmails = emails.filter((email) => {
                const emailDate = DateTime.fromISO(email.created_at);
                const fromDate = DateTime.fromJSDate(range.from).startOf("day");
                const toDate = DateTime.fromJSDate(range.to).endOf("day");

                console.log({
                    emailDate: emailDate.toISO(),
                    fromDate: fromDate.toISO(),
                    toDate: toDate.toISO(),
                    isWithinRange: emailDate >= fromDate && emailDate <= toDate,
                });

                return emailDate >= fromDate && emailDate <= toDate;
            });
        } else {
            filteredEmails = emails;
        }

        return filteredEmails.map((email) => ({
            sender: email.entity,
            subject: email.subject,
            message: email.text.replace(/<[^>]+>/g, ""),
            recipients: (email.emails || []).join(", "),
        }));
    }, [emails, range]);

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setRange({ from: undefined, to: undefined });
    };

    const handleDownload = () => {
        // Create CSV header row
        const headers = ['Sender', 'Subject', 'Message content', 'Recipient list'];

        // Convert data to CSV rows
        const csvRows = [
            headers.join(','), // Header row
            ...csvData.map(row => [
                `"${row.sender || ''}"`,
                `"${row.subject || ''}"`,
                `"${row.message || ''}"`,
                `"${row.recipients || ''}"`,
            ].join(','))
        ].join('\n');

        // Create blob and download
        const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'email_history.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toggleModal();
    };

    const formatDateRange = () => {
        if (!range.from && !range.to) return 'Select date range';
        if (!range.to) return `From ${range.from.toLocaleDateString()}`;
        return `${range.from.toLocaleDateString()} - ${range.to.toLocaleDateString()}`;
    };

    return (
        <>
            <Button variant={"secondary"} onClick={toggleModal} icon={SvgDownload}>
                Download Email History
            </Button>
            <ModalComponent
                showScrollBar
                isOpen={isOpen}
                backgroundColor={"white"}
                header="Download Email History"
                headerToggle
                size={"lg"}
                toggle={toggleModal}
                FooterComponent={
                    <div className="flex flex-row items-center gap-3">
                        <Button variant={"secondary"} onClick={toggleModal}>
                            Cancel
                        </Button>

                        <Button
                            onClick={handleDownload}
                            disabled={!range.from || !range.to}
                        >
                            Download CSV
                        </Button>
                    </div>
                }
                footerAlignment={"right"}
            >
                <div className="flex flex-col gap-5 w-full">
                    <p className="body2">
                        Select a date range and click the button below to
                        download a CSV file containing your email history.
                    </p>
                    <Popover.Root>
                        <Popover.Trigger asChild>
                            <Button variant="secondary" className="w-[300px] justify-start text-left font-normal">
                                {formatDateRange()}
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content align="start" className="bg-white p-6 rounded-md shadow-2xl" sideOffset={4}>
                            <div className="flex flex-col gap-4">
                                <DayPicker
                                    mode="range"
                                    selected={range}
                                    onSelect={setRange}
                                    numberOfMonths={2}
                                    defaultMonth={
                                        new Date(
                                            new Date().setMonth(
                                                new Date().getMonth() - 1
                                            )
                                        )
                                    }
                                    disabled={{
                                        after: new Date()
                                    }}
                                />
                                <Popover.Close asChild>
                                    <Button
                                        className="w-full"
                                        disabled={!range.from || !range.to}
                                    >
                                        Done
                                    </Button>
                                </Popover.Close>
                            </div>
                        </Popover.Content>
                    </Popover.Root>
                </div>
            </ModalComponent>
        </>
    );
}
