import React, { useState, useEffect, useMemo } from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingStepWrapper from './OnboardingStepWrapper';
import OnboardingStepButtons from './OnboardingStepButtons';
import InputWrapper from '../atoms/inputs/InputWrapper';
import MultiSelectComponent from '../atoms/MultiSelectComponent';
import { getSchoolClubs } from '../../api/student/clubs'; 
import { connect } from 'react-redux';
import SelectComponent from '../atoms/SelectComponent';
import SearchMajors from '../profile/SearchMajors';
import SvgCheck from '../icons/SvgCheck';

function mapStateToProps(state) {
    return {
       user: state.userReducer.user,
       authUser: state.userReducer.authUser,
    };
}

function OnboardingEducationStep({ goNextStep, goPreviousStep, onEducationSubmit, haveJob, lastStep, authUser, user }) {
    const [formData, setFormData] = useState({
        gpa: '',
        classYear: '',
        graduationSeason: '',
        clubs: []
    });

    const [noGpa, setNoGpa] = useState(false);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [clubOptions, setClubOptions] = useState([]);

    const clubsNamesMap = useMemo(() => {
        return Object.fromEntries(clubOptions.map(club => [club.id, club.name]));
    }, [clubOptions]);
    const clubsIdsMap = useMemo(() => {
        return Object.fromEntries(clubOptions.map(club => [club.name, club.id]));
    }, [clubOptions]);

    const selectedClubNames = useMemo(() => {
        return formData.clubs.map(clubId => clubsNamesMap[clubId]);
    }, [formData.clubs, clubsNamesMap]);

    const [selectedMajors, setSelectedMajors] = useState([]);
    const [selectedMinors, setSelectedMinors] = useState([]);


    useEffect(() => {
        const fetchClubs = async () => {
            const res = await getSchoolClubs(authUser, user.college);
            console.log(res)
            setClubOptions(res.clubs.filter(club => club.demo !== true));
        };
        fetchClubs();
    }, [authUser, user.college]);

    const handleGradYearChange = (e) => {
        const text = e.target.value;
        if (text === "" || (text.length <= 4 && /^\d*$/.test(text)) && Number(text) <= 2030) {
            const newFormData = { ...formData, classYear: text };
            setFormData(newFormData);
            validateForm(newFormData, noGpa, 'classYear');
        }
    };

    const handleGradSeasonChange = (selectedSeason) => {
        setFormData({ ...formData, graduationSeason: selectedSeason });
    };

    const handleGPAChange = (e) => {
        const text = e.target.value;
        if (text === '' || /^\d*\.?\d*$/.test(text)) {
            const newFormData = { ...formData, gpa: text };
            setFormData(newFormData);
            validateForm(newFormData, noGpa, 'gpa');
        }
    };

    const handleClubsChange = (selectedClubName) => {
        let updatedClubs;
        if (selectedClubNames.includes(selectedClubName)) {
            updatedClubs = formData.clubs.filter(clubId => clubsNamesMap[clubId] !== selectedClubName);
        } else if (selectedClubName === null) {
            updatedClubs = [];
        } else {
            updatedClubs = [...formData.clubs, clubsIdsMap[selectedClubName]];
        }
        setFormData({ ...formData, clubs: updatedClubs });
    };

    const toggleCheckbox = () => {
        const newNoGpa = !noGpa;
        setNoGpa(newNoGpa);
        if (newNoGpa) {
            const newFormData = { ...formData, gpa: '' };
            setFormData(newFormData);
            validateForm(newFormData, newNoGpa, 'gpa');
        }
    };

    const validateForm = (dataToValidate = formData, noGpaValue = noGpa, fieldToValidate = null) => {
        let formErrors = {};

        if (!fieldToValidate || fieldToValidate === 'classYear') {
            if (!dataToValidate.classYear || dataToValidate.classYear.length !== 4 || !(/^\d*\.?\d*$/.test(dataToValidate.classYear))) {
                formErrors.classYear = "Please enter a valid 4-digit graduation year";
            }
        }

        if (!fieldToValidate || fieldToValidate === 'gpa') {
            if (!noGpaValue && (!dataToValidate.gpa || parseFloat(dataToValidate.gpa) < 0 || parseFloat(dataToValidate.gpa) > 4)) {
                formErrors.gpa = "Please enter a valid GPA between 0 and 4";
            }
        }

        const hasErrors = Object.keys(formErrors).length > 0;
        setIsFormValid(!hasErrors);
        setErrors(hasErrors ? formErrors : {});
        
        return hasErrors ? formErrors : null;
    };

    const handleSubmit = () => {
        if (isFormValid) {
            const updatedFormData = {
                ...formData,
                major: selectedMajors,
                minor: selectedMinors
            };
            onEducationSubmit(updatedFormData);
            goNextStep();
        }
    };

    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={'Tell us about yourself'}
                description={'Please provide your major, minor, GPA, graduation year, and clubs'}
            />
            <div className='flex flex-col gap-3 md:gap-5'>
                <SearchMajors 
                    selectedMajors={selectedMajors} 
                    setSelectedMajors={setSelectedMajors} 
                    outerLabel="Major(s)" 
                />
                <div className='flex-1 hidden md:block'>
                    <SearchMajors 
                        selectedMajors={selectedMinors} 
                        setSelectedMajors={setSelectedMinors} 
                        outerLabel="Minor(s)" 
                    />
                </div>
                {!noGpa && (
                    <InputWrapper
                        label='GPA*'
                        type='text'
                        inputMode='decimal'
                        pattern='[0-9]*[.]?[0-9]*'
                        name='gpa'
                        placeholder='ie. 3.6'
                        value={formData.gpa}
                        onChange={handleGPAChange}
                        error={errors?.gpa}
                    />
                )}
                <div className='flex-1'>
                    <button onClick={toggleCheckbox} className={`flex flex-row items-center ml-2 mt-1 w-max`}>
                        <div className={`${noGpa ? 'checkbox-active' : 'checkbox border-slate-200'} mr-2`}>
                            {noGpa ? <SvgCheck className={'base-white-100'} /> : null}
                        </div>
                        <p className='body2 text-slate-500'>I don't have a GPA yet</p>
                    </button>
                </div>
                <InputWrapper
                    label='Graduation Year*'
                    type='text'
                    name='classYear'
                    placeholder='ie. 2024'
                    value={formData.classYear}
                    onChange={handleGradYearChange}
                    error={errors?.classYear}
                />
                <div className='flex-1'>
                    <p className='text-sm text-slate-500 font-semibold mb-1'>Graduation Season</p>
                    <SelectComponent
                        label={'Select graduation season'}
                        value={formData.graduationSeason}
                        light
                        setValue={handleGradSeasonChange}
                        options={[
                            { title: 'Fall', value: 'Fall' },
                            { title: 'Spring', value: 'Spring' }
                        ]}
                    />
                </div>
                {clubOptions.length > 0 && (
                    <div className='flex-1'>
                        <p className='text-sm text-slate-500 font-semibold mb-1'>Club(s)</p>
                    <MultiSelectComponent
                        label={'Select your clubs'}
                        value={selectedClubNames}
                        light
                        setValue={handleClubsChange}
                        clearFilter={() => handleClubsChange(null)}
                        options={clubOptions.map(club => ({ title: club.name, club: club }))}
                        scrollable={true}
                        />
                    </div>
                )}
            </div>
            <OnboardingStepButtons
                goNextStep={handleSubmit}
                goPreviousStep={goPreviousStep}
                nextLabel={lastStep ? "Finish": "Next"}
                isNextDisabled={!isFormValid}
            />
        </OnboardingStepWrapper>
    );
}

export default connect(mapStateToProps)(OnboardingEducationStep);
