import React, { useRef, useState } from "react";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import {CLASS_YEARS, MAJOR_OPTIONS} from "../../../utils/dummy";
import MultiSelectComponent from "../../../components/atoms/MultiSelectComponent";
import SvgSearch from "../../../components/icons/SvgSearch";
import CollapseComponent from "../../../components/atoms/CollapseComponent";
import FadeInOnScroll from "../../../components/atoms/FadeInOnScroll";
import SvgClose from "../../../components/icons/SvgClose";
import TagComponent from "../../../components/atoms/TagComponent";
import {searchClubs, searchColleges} from "../../../utils/typesense";
import SelectComponent from "../../../components/atoms/SelectComponent";
import { searchSmartLists, searchClubLists } from "../../../utils/recruiter_typesense";
import Checkbox from "../../../components/atoms/Checkbox";
import { Button } from "../../../components/atoms/Button";
import SvgSearchFeather from "../../../components/icons/SvgSearchFeather";
import HorizontalDivider from "../../../components/atoms/HorizontalDivider";
import SvgList from "../../../components/icons/SvgList";
import SvgCheck from "../../../components/icons/SvgCheck";
import {connect} from "react-redux";
import * as Portal from "@radix-ui/react-portal";
import { usePopper } from 'react-popper';

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
    };
}

function CreateJobQualificationStep(props) {
    const [schoolSearch, setSchoolSearch] = useState('');
    const [clubSearch, setClubSearch] = useState('');
    const [clubOptions, setClubOptions] = useState([]);
    const [schoolOptions, setSchoolOptions] = useState([]);
    const [smartLists, setSmartLists] = useState([]);
    const [clubLists, setClubLists] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            { name: 'offset', options: { offset: [0, 8] } },
            { name: 'preventOverflow', options: { padding: 8 } },
        ],
    });

    const resultsEndRef = useRef();

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setSearch(text);
        scrollToBottom();
        if (text) {
            setIsDropdownOpen(true);
        } else {
            setIsDropdownOpen(false);
        }
        searchLists(text);
    };

    const scrollToBottom = () => {
        if (resultsEndRef.current) {
            resultsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const searchLists = async (text) => {
        const smartListRes = await searchSmartLists(text, 1, props.user.company_id);
        const clubListRes = await searchClubLists(text, 1, props.user.company_id);

        setSmartLists(smartListRes);
        setClubLists(clubListRes);
    }

    const handleClubSearchChange = async (event) => {
        const text = event.target.value;
        setClubSearch(text);
        const clubs = await searchClubs(text, 1, 5, props.schools.join(","));
        setClubOptions(clubs);
    }

    const handleSchoolSearchChange = async (event) => {
        const text = event.target.value;
        setSchoolSearch(text);

        const schools = await searchColleges(text);
        setSchoolOptions(schools);
    }

    const selectSchool = (school) => {
        props.updateSchools(school);
        setSchoolSearch('');
        setSchoolOptions([]);
    }

    const selectClubList = (res) => {
        setSearch('');
        props.selectClubList(res);
    }

    const selectSmartList = (res) => {
        setSearch('');
        props.selectSmartList(res);
    }

    const closeDropdown = () => {
        setIsDropdownOpen(false);
        setSearch("");
    };

    const renderSelectedIcon = (isSelected, className) => {
        return isSelected ?
            <div
                className={`w-5 h-5 rounded-full flex flex-col items-center justify-center bg-primary ${className || 'ml-auto'}`}>
                <SvgCheck className="w-4 h-4 text-white"/>
            </div>
            : null;
    };

    const deleteList = (item) => {
        if (item.type === "club") {
            selectClubList(item);
        } else if (item.type === "smart") {
            selectSmartList(item);
        }
    }

    const qualifiedOptions = [
        {label: "Exclusive", value: "qualified", description: `Only the selected recipients can apply to this ${props.event ? 'event' : 'job'}`},
        {label: "Everyone", value: "recommended", description: `Anyone can apply to this job`}
    ]

    const checkboxes = [
        {
            label: 'Veteran',
            active: props.veteran,
            onClick: () => props.handleQualificationCheckbox('veteran')
        },
        {
            label: 'LGBTQ',
            active: props.lgbtq,
            onClick: () => props.handleQualificationCheckbox('lgbtq')
        },
        {
            label: 'Disability',
            active: props.disabled,
            onClick: () => props.handleQualificationCheckbox('disabled')
        },
        {
            label: 'Authorized to work',
            active: props.authorized,
            onClick: () => props.handleQualificationCheckbox('authorized')
        },
    ];

    return (
        <div className='flex flex-col gap-5' ref={resultsEndRef}>
            {!props.hideTutorial &&
                <p className='text-lg text-slate-800 font-semibold'>
                    {`Choose who to share this ${props.event ? 'event' : 'job'} with`}
                </p>
            }
            <>
                <p className='text-md text-slate-500 font-semibold text-slate-800'>
                    How would you like to share your {props.event ? 'event listing' : 'job posting'}?
                </p>
                <fieldset className="grid grid-cols-2 gap-4">
                    {qualifiedOptions.map((option) => (
                        <div key={option.value}>
                            <input
                                type="radio"
                                name="locationOption"
                                value={option.value}
                                id={option.value}
                                className="peer hidden [&:checked_+_label_svg]:block"
                                checked={option.value === 'qualified' ? props.qualifiedOnly : !props.qualifiedOnly}
                                onChange={() => props.handleQualifiedOnlyChange(option.value === 'qualified')}
                            />
                            <label
                                htmlFor={option.value}
                                className={`block cursor-pointer rounded-lg border  bg-white p-3 text-sm font-medium shadow-sm ${
                                    (option.value === 'qualified' && props.qualifiedOnly) || 
                                    (option.value === 'recommended' && !props.qualifiedOnly)
                                        ? "border-blue-500"
                                        : "border-gray-100 hover:border-gray-200"
                                }`}
                            >
                                <div className="flex items-center justify-between gap-2">
                                    <div className="flex flex-col gap-2">
                                        <p className='text-md text-slate-800 font-semibold'>
                                            {option.label}
                                        </p>
                                        <p className='text-sm text-slate-500'>
                                            {option.description}
                                        </p>
                                    </div>
                                    <div className="w-6 h-6">
                                        <svg
                                            className={`h-5 w-5 text-blue-600 ${
                                                (option.value === 'qualified' && props.qualifiedOnly) || 
                                                (option.value === 'recommended' && !props.qualifiedOnly) 
                                                    ? 'block' 
                                                    : 'hidden'
                                            }`}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </label>
                        </div>
                    ))}
                </fieldset>
            </>
            {!props.editing && props.qualifiedOnly ? 
            <div className='flex flex-col gap-1'>
                <div className='text-xs font-medium text-slate-500'>
                    Recipients
                </div>
                <div className='relative'>
                    <div className={'relative'}>
                        <WrappedTextInput
                            ref={setReferenceElement}
                            type="text"
                            placeholder='Search your smart lists and club lists'
                            className='pl-44 input-light body2 w-full'
                            value={search}
                            onChange={handleSearchChange}
                            icon={<SvgSearchFeather width={20} height={20}
                                                    className={'absolute text-slate-500'}
                                                    style={{left: 12, top: 10}}/>}
                        />
                        <div
                            className='absolute right-2 top-0 bottom-0 flex flex-col items-center justify-center'>
                            {isDropdownOpen && (
                                <div className="absolute w-36 right-2 top-0 bottom-0 flex flex-col items-end justify-center">
                                    

                                   
                                        <Button size={"xs"} variant={'secondary'} onClick={closeDropdown}>
                                            Close search
                                        </Button>
                                    
                                </div>
                            )}
                        </div>
                    </div>
                    <Portal.Root>
                        <div
                            ref={setPopperElement}
                            style={{
                                ...styles.popper,
                                zIndex: 1060,
                                width: referenceElement?.offsetWidth ? `${referenceElement.offsetWidth}px` : 'auto'
                            }}
                            {...attributes.popper}
                        >
                            <CollapseComponent
                                isOpen={isDropdownOpen}
                                className='bg-white shadow-lg border shadow-xl border-neutral-200 rounded-lg p-2 max-h-[45vh] overflow-y-scroll'>
                                <div>
                                    <p className='text-sm mb-1 mt-2 font-medium'>Smart Lists</p>
                                    {smartLists.map((res) => {
                                        const isSelected = props.smartListIds.some((selected) => selected === res.id);
                                        return (
                                            <div
                                                key={res.id}
                                                className={`flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2`}
                                                onClick={() => selectSmartList(res)}
                                            >
                                                <div
                                                    className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                                                    <SvgList className={'w-5 h-5 text-slate-700'}/>
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="text-sm text-slate-800 line-clamp-2">{res.name}</p>
                                                    <p className="text-xs text-slate-500 line-clamp-2">
                                                        Smart List - Created by {res.creator_name}
                                                    </p>
                                                </div>
                                                {renderSelectedIcon(isSelected)}
                                            </div>
                                        );
                                    })}
                                    {smartLists.length === 0 && (
                                        <div className='flex flex-col items-center mt-1'>
                                            <p className="text-sm text-slate-800 line-clamp-2">No smart lists for this search</p>
                                        </div>
                                    )}
                                </div>
                                <HorizontalDivider className='bg-slate-200 my-3'/>
                                <div>
                                    {/* Club Lists Section */}
                                    <p className='text-sm mb-1 mt-2 font-medium'>Club Lists</p>
                                    {clubLists.map((res) => {
                                        const isSelected = props.clubListIds.some((selected) => selected.id === res.id);
                                        return (
                                            <div
                                                key={res.id}
                                                className={`flex flex-row items-center gap-3 py-2 cursor-pointer ${!isSelected && 'hover:bg-slate-100'} rounded-xl px-2`}
                                                onClick={() => selectClubList(res)}
                                            >
                                                <div
                                                    className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                                                    <SvgList className={'w-5 h-5 text-slate-700'}/>
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="text-sm text-slate-800 line-clamp-2">{res.name}</p>
                                                    <p className="text-xs text-slate-500 line-clamp-2">
                                                        Club List - Created by {res.creator_name}
                                                    </p>
                                                </div>
                                                {renderSelectedIcon(isSelected)}
                                            </div>
                                        );
                                    })}
                                    {clubLists.length === 0 && (
                                        <div className='flex flex-col items-center mt-1'>
                                            <p className="text-sm text-slate-800 line-clamp-2">No club lists for this search</p>

                                        </div>
                                    )}
                                </div>
                            </CollapseComponent>
                        </div>
                    </Portal.Root>
                </div>
                {(props.listNames && props.listNames.length) ? 
                <div className='items-center flex flex-row flex-wrap gap-1 mt-2'>
                    {props.listNames.map((item, index) =>
                        <TagComponent
                            key={item.id}
                            label={item.name}
                            removable
                            deleteTag={() => deleteList(item)}
                            truncate
                            showClose
                            className={'p-1'}
                        />
                    )}
                </div>
                : null}
            </div>
            : null }
            {props.recruiter ?
                <>
                    <div className='flex flex-row justify-between gap-3'>
                        <div className='flex-[1] relative'>
                            <WrappedTextInput
                                type="text"
                                outerLabel={'Schools'}
                                placeholder={'ie. Harvard, UChicago, ...'}
                                className='pl-44 input-light flex-1 px-3 body2 w-full'
                                value={schoolSearch}
                                onChange={handleSchoolSearchChange}
                                icon={<SvgSearch width={24} height={24}
                                                className={'absolute text-slate-500'}
                                                style={{left: 12, top: 10}}/>}
                            />
                            <div className='flex flex-row items-center gap-2 mt-2 flex-wrap'>
                                {props.schools.filter(e => e).map(item =>
                                    <div key={item} onClick={() => props.updateSchools(item)}
                                        className='relative group cursor-pointer'>
                                        <div
                                            className='h-[40px] px-2 text-xs flex flex-col items-center justify-center border border-slate-200 group-hover:border-red-500 rounded-lg object-cover'>
                                            <p>{item.slice(0, 20) + (item.length > 20 ? '...' : '')}</p>
                                        </div>
                                        <div className='absolute top-[-4px] right-[-8px] bg-slate-200  rounded-full  p-1'>
                                            <SvgClose className={'w-3 h-3 group-hover:text-red-500'}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='absolute left-0 right-0 z-10 top-16'>
                                <CollapseComponent
                                    className={'bg-white rounded-lg border border-slate-200 shadow-xl'}
                                    isOpen={schoolSearch.length !== 0}>
                                    {schoolOptions.map((res) =>
                                        <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                            <div
                                                onClick={() => selectSchool(res.name)}
                                                className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                                <div className='flex flex-col'>
                                                    <p className='body1-bold text-slate-800'>
                                                        {res.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </FadeInOnScroll>
                                    )}
                                </CollapseComponent>
                            </div>
                    </div>
                    </div>
                    <MultiSelectComponent
                        className={'flex-1'}
                        outerLabel={'Graduation Year'}
                        label={'ie. 2025, 2026...'}
                        value={props.classYears}
                        light
                        setValue={props.updateClassYears}
                        clearFilter={() => props.updateClassYears(null)}
                        options={CLASS_YEARS}
                        node={document.getElementById('add-joblisting-modal')}
                    />
                    {/*Todo: automatically select the clubs the student is in, they can add or remove from there. The search should return only the clubs they are in*/}
                    <div className='flex-[1] relative'>
                        <WrappedTextInput
                            type="text"
                            outerLabel={'Clubs'}
                            placeholder={'ie. Harvard Business Club...'}
                            className='pl-44 input-light flex-1 px-3 body2 w-full'
                            value={clubSearch}
                            onChange={handleClubSearchChange}
                            icon={<SvgSearch width={24} height={24}
                                            className={'absolute text-slate-500'}
                                            style={{left: 12, top: 10}}/>}
                        />
                        <div className='flex flex-row items-center gap-2 mt-2 flex-wrap z-[99]'>
                            {props.clubs.map(item =>
                                <div key={item.id} className='relative group cursor-pointer' onClick={() => props.updateClubs(item)}>
                                    <img
                                        className='h-[40px] aspect-square border border-slate-200 group-hover:border-red-500 rounded-lg object-contain'
                                        src={item.logo_url}
                                        alt={item.name}
                                    />
                                    <div className='absolute top-[-4px] right-[-8px] bg-slate-200  rounded-full  p-1'>
                                        <SvgClose className={'w-3 h-3 group-hover:text-red-500'}/>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='absolute left-0 right-0 z-10 top-16'>
                            <CollapseComponent className={'bg-white rounded-lg border border-slate-200 shadow-xl'}
                                            isOpen={clubSearch.length !== 0}>
                                {/*Todo:refocus the search input*/}
                                {clubOptions.map((res) =>
                                    <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                        <div
                                            // onClick={() => selectClub(res)}
                                            className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                            <img
                                                className='h-[40px] aspect-square border border-slate-200 bg-white rounded-lg object-contain'
                                                src={res.logo_url}
                                                alt={res.name}
                                            />
                                            <div className='flex flex-col'>
                                                <p className='body1-bold text-slate-800'>
                                                    {res.name}
                                                </p>
                                            </div>
                                        </div>
                                    </FadeInOnScroll>
                                )}
                            </CollapseComponent>
                        </div>
                    </div>
                </>
            : null}
            <div className={`grid ${props.recruiter ? 'grid-cols-2' : 'grid-cols-1'} gap-2`}>
                {props.recruiter && 
                    <MultiSelectComponent
                        outerLabel={'Majors'}
                        label={'ie. Finance, Economics'}
                        value={props.majors}
                        light
                        setValue={props.updateMajors}
                        clearFilter={() => props.updateMajors(null)}
                        options={MAJOR_OPTIONS}
                        direction={'top'}
                        node={document.getElementById('add-joblisting-modal')}
                    />
                }
                {props.recruiter ? 
                    <WrappedTextInput
                        type="numeric"
                        outerLabel={'Minimum GPA'}
                        placeholder={'ie. 3.8'}
                        className='input-light px-3  w-full'
                        value={props.gpa}
                        onChange={(e) => props.handleInputChange(e, 'gpa')}
                    />
                : null}
            </div>
    
            {props.recruiter && (
                <>
                    <div className='flex flex-row gap-2'>
                        <SelectComponent
                            label='Gender'
                            light
                            value={props.gender}
                            scrollable={true}
                            setValue={props.updateGender}
                            clearFilter={() => props.updateGender(null)}
                            options={[{title: "Male"}, {title: "Female"}, {title: "Prefer Not to Specify"}]}
                            node={document.getElementById('add-joblisting-modal')}
                        />
                        <MultiSelectComponent
                            className={'min-w-[120px]'}
                            label='Race'
                            light
                            value={props.race}
                            scrollable={true}
                            setValue={(value) => props.updateRace(value)}
                            clearFilter={() => props.updateRace(null)}
                            options={[
                                {title: "White"},
                                {title: "Hispanic or Latino"},
                                {title: "Black or African American"},
                                {title: "American Indian or Native American"},
                                {title: "Asian or Pacific Islander"},
                                {title: "Other"},
                                {title: "Prefer Not to Specify"}
                            ]}
                            node={document.getElementById('add-joblisting-modal')}
                        />
                        {checkboxes.map(checkbox => (
                           <Checkbox key={checkbox.label} label={checkbox.label} checked={checkbox.active} onClick={checkbox.onClick} className={'px-2'}/>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default connect(mapStateToProps)(CreateJobQualificationStep)