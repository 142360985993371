import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import SvgPin from "../components/icons/SvgPin";
import TagComponent from "../components/atoms/TagComponent";
import SvgPlus from "../components/icons/SvgPlus";
import SvgBriefcase from "../components/icons/SvgBriefcase";
import ProfilePicture from "./atoms/ProfilePicture";
import { DateTime } from 'luxon';


class RecruiterJobPreviewCard extends Component {

    navigateToJobListing = (jobId) => {
        this.props.history.push({
            pathname: `/job-listing/${jobId}`,
            params: {
                name: this.props.item.name
            }
        })
    }

    render() {
        const item = this.props.item
        const classYears = item.class_years || [];
        let yearText = '';

        if (classYears.length === 1) {
            yearText = `Class of '${classYears[0].toString().slice(-2)}`;
        } else if (classYears.length > 1) {
            const minYear = classYears[0].toString().slice(-2);
            const maxYear = classYears[classYears.length - 1].toString().slice(-2);
            yearText = `Class of '${minYear} - '${maxYear}`;
        }
        const city = item.city || [];
        const state = item.state || [];
        const images = item.images || [];
        let date = null;
        if (!!item.deadline) {
            date = DateTime.fromISO(item.deadline)
        }
        return (

            <div key={item.id}
                 onClick={() => this.navigateToJobListing(item.id)}
                 className='bg-white cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 flex flex-col justify-between border border-slate-200 rounded-xl'
            >
                <div>
                    <div className='flex flex-row justify-between gap-3'>
                        <div>
                            <div className='flex flex-row text-primary items-center gap-1.5'>
                                <SvgBriefcase className={'w-3.5 h-3.5'}/>
                                <p className='text-sm text-primary font-semibold'>
                                    {yearText}
                                </p>
                            </div>
                            <p className='text-xl font-semibold text-slate-800'>
                                {item.name}
                            </p>
                        </div>
                        {/*<div className='text-center'>*/}
                        {/*    <p className='text-xs text-slate-500 font-semibold'>*/}
                        {/*        Ends:*/}
                        {/*    </p>*/}
                        {/*    <p className='text-sm whitespace-nowrap text-slate-500 font-semibold'>*/}
                        {/*        2 days*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                    </div>
                    <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                        {!!city.length && state.length ?
                            <div className='flex flex-row items-center gap-1'>
                                <SvgPin className={'w-3.5 w-3.5'}/>
                                <p className='text-md '>
                                    {item.city[0]}, {item.state[0]}
                                </p>
                            </div>
                            : null}
                        {!!city.length && state.length && !!item.openings ?
                            <p>
                                •
                            </p>
                            : null
                        }
                        {!!item.openings ?
                            <p className='text-md '>
                                <span className=''>{item.openings}</span> openings
                            </p>
                            : null}
                    </div>
                    <div className='mr-a mt-3 flex flex-row items-center gap-2 wrap'>
                        {/*<TagComponent label={'Class of 2024'}/>*/}
                        <TagComponent label={item.type}/>
                        {!!item.secondary_specialties && item.secondary_specialties.length ?
                            <>
                                {item.secondary_specialties.map(specialty =>
                                    <TagComponent
                                        label={specialty}
                                    />
                                )}
                            </>
                            :
                            <TagComponent label={item.industry}/>
                        }
                    </div>
                </div>
                <div className='flex flex-row justify-between mt-5 items-center'>
                    <div className='flex flex-row items-center relative gap-3'>
                        {images.length === 0 ?
                            <div
                                className='w-8 h-8 text-xs text-slate-400 text-center flex flex-col items-center justify-center aspect-square border-slate-200 bg-slate-100 border rounded-full'
                            >
                                <p>
                                    N/A
                                </p>
                            </div>
                            :
                            <div className='flex flex-row items-center relative'>
                                {images.map((item, index) => {
                                    return (
                                        <ProfilePicture
                                            image={item}
                                            className={`${index !== 0 && '-ml-5'} border border-slate-200`}
                                        />
                                    )
                                })}
                                {!!images.length ?
                                    <div
                                        className='absolute z-[3] border border-white bg-primary bottom-[-2px] right-[-4px] rounded-full p-0.5 cursor-pointer'>
                                        <SvgPlus className={'w-3 h-3 text-white'}/>
                                    </div>
                                    : null}
                            </div>
                        }
                        <p className='text-sm font-semibold text-slate-500'>
                            {item.num_applicants} applicant{item.num_applicants === 1 ? "" : "s"}
                        </p>
                    </div>
                    <div className='flex flex-row items-center'>
                        {date &&
                            <div
                                className='flex flex-col items-center rounded-lg border-neutral-200 border overflow-hidden'>
                                <p className='body4-bold uppercase p-0.5 px-2 bg-neutral-100'>
                                    {date.toFormat("MMM")}
                                </p>
                                <p className='body2-bold p-0.5'>
                                    {date.toFormat("d")}
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(RecruiterJobPreviewCard)
