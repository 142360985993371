import React from "react";
import JobPageTitleWidget from "../../../components/jobpage/JobPageTitleWidget";
import JobPageAboutCompanyWidget from "../../../components/jobpage/JobPageAboutCompanyWidget";
import JobPageAboutJobWidget from "../../../components/jobpage/JobPageAboutJobWidget";
import { connect } from "react-redux";

function mapStateToProps(state) {
    return {
        recruiterCompany: state.userReducer.recruiterCompany,
        user: state.userReducer.user,
        authUser: state.userReducer.authUser
    }
}

function CreateJobPreviewStep({ listingState, user, authUser, recruiterCompany }) {
    const listing = {
        ...listingState,
        company_id: recruiterCompany.id,
        company_name: recruiterCompany.name,
        company: recruiterCompany
    }

    return (
        <div className="flex flex-col gap-5">
            <JobPageTitleWidget
                listing={listing}
                user={user}
                authUser={authUser}
            />
            <JobPageAboutJobWidget listing={listing} loading={false}/>
             {/* Add Application Questions Preview Section */}
             {listing.application_questions?.length > 0 && (
                <div className="card">
                    <p className="text-xl font-semibold text-slate-800 mb-5">
                        Questions
                    </p>
                    
                    <ul className="w-full mt-6">
                        {listing.application_questions.map((question, index) => (
                            <li key={index} className="flex-col mb-8">
                                <p className="text-slate-700 font-semibold font-base mb-3">
                                    <span className='font-bold'>
                                        {index + 1}.&nbsp;
                                    </span>
                                    {question.text}
                                </p>
                                <textarea
                                    disabled
                                    placeholder="Applicant's answer will appear here"
                                    className="input-light text-sm px-3 py-3 body2 base-black-50 w-full min-h-[100px] bg-slate-50"
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <JobPageAboutCompanyWidget 
                listing={listing} 
                authUser={authUser}
                user={user} 
                setUser={() => {}} 
                hideFollowButton
            />

           
        </div>
    );
}

export default connect(mapStateToProps)(CreateJobPreviewStep);

