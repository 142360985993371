import React, {Component} from "react";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import SelectComponent from "../../../components/atoms/SelectComponent";
import { JOB_TYPE_OPTIONS } from "../../../utils/dummy";
import RichTextEditor from "../../../components/atoms/inputs/RichTextEditor";
import RecruiterUploadPdf from "../RecruiterUploadPdf";

class CreateJobDetailsStep extends Component {
    render() {
        const ROLES_TAGS = this.props.constants.secondary_tags.map(tag => ({title: tag, value: tag}));
        return (
            <div className='flex flex-col gap-5'>
                {!this.props.hideTutorial &&
                    <p className='text-lg text-slate-800 font-semibold'>
                        Tell us about the job you’re looking to fill.
                    </p>
                }
                <WrappedTextInput
                    type="text"
                    outerLabel={'Job Title'}
                    placeholder={'ie. Investment Banking Junior Analyst'}
                    className='input-light px-3 w-full'
                    value={this.props.jobTitle}
                    onChange={(e) => this.props.handleInputChange(e, 'jobTitle')}
                />

                <div className='flex flex-row items-center justify-between gap-3'>
                    <SelectComponent
                        className={'flex-1'}
                        outerLabel={'Industry'}
                        label={'ie. Investment Banking'}
                        value={this.props.role}
                        light
                        setValue={this.props.handleSelectChange}
                        clearFilter={() => this.props.handleSelectChange('role', null)}
                        stateName={'role'}
                        options={ROLES_TAGS}
                        node={document.getElementById('add-joblisting-modal')}
                        scrollable
                    />
                    <SelectComponent
                        className={'flex-1'}
                        outerLabel={'Type'}
                        label={'Internship / Full Time'}
                        value={this.props.jobType}
                        light
                        setValue={this.props.handleSelectChange}
                        clearFilter={() => this.props.handleSelectChange('jobType', null)}
                        stateName={'jobType'}
                        options={JOB_TYPE_OPTIONS}
                        node={document.getElementById('add-joblisting-modal')}
                    />

                </div>
                <RecruiterUploadPdf 
                    type="jobs_pdf" 
                    onUpload={this.props.onJobPdfUpload}
                    jobPdfPath={this.props.jobPdfPath}
                    id={this.props.jobId}
                />
                <div className={`flex flex-col gap-1.5`}>
                    <div className='text-xs font-medium text-slate-500'>
                        Job Description
                    </div>
                    <div>
                        <RichTextEditor editorState={this.props.editorState} setEditorState={this.props.setEditorState}/>
                    </div>

                </div>
                {/* <WrappedTextInput
                    type="number"
                    outerLabel={'Number of Openings'}
                    placeholder={'ie. 5'}
                    className='input-light px-3 w-full'
                    value={this.props.openings}
                    onChange={(e) => this.props.handleInputChange(e, 'openings')}
                /> */}
            </div>
        )
    }
}

export default CreateJobDetailsStep
