import React, {Component} from "react";
import {connect} from "react-redux";
import RecruiterClubSearch from "../components/pages/RecruiterClubSearch";
import SectionTabs from "../components/atoms/SectionTabs";
import RecruiterStudentSearch from "../components/pages/RecruiterStudentSearch";
import RecruiterCollegeSearch from "../components/pages/RecruiterCollegeSearch";
import RecruiterPaywall from "./atoms/RecruiterPaywall";
import {Button} from "../components/atoms/Button";
import SvgStars from "../components/icons/SvgStars";
import SvgPlus from "../components/icons/SvgPlus";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        tier: state.userReducer.tier
    };
}

class RecruiterSearchPage extends Component {
    state = {
        activeTab: 'Students',
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("active_tab", tab);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        this.props.history.replace(newUrl);
    };

    componentDidMount = () => {
        window.analytics.page("Recruiter Search Page");

        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("active_tab");
        if (!!activeTab && activeTab !== "Students") this.changeTab(activeTab);
    }

    render() {
        const TABS = this.props.tier === 'blk' ? ['Students'] : ['Students', 'Clubs'];
        const showPaywall = this.props.tiers === 'basic';
        const activeTab = this.state.activeTab;
        return (
            <div className={`flex flex-col flex-1 p-5 gap-3 relative overflow-hidden`}>
                <div className='flex flex-row items-center justify-between'>
                    <p className='text-slate-800 text-2xl font-semibold'>
                        Search
                    </p>
                    <div className="flex flex-row gap-3">
                        <Button
                            variant={'secondary'}
                            icon={SvgPlus}
                            onClick={() => {
                                this.props.history.push({
                                    pathname: "/saved-lists",
                                    state: {openCreate: activeTab},
                                });
                            }}
                        >
                            New {activeTab} List
                        </Button>
                    </div>
                </div>
                <div className='flex flex-col gap-3 flex-1 overflow-hidden'>
                    <div className="flex flex-row items-end justify-between">
                        <div className={'flex-1 mr-5'}>
                            <SectionTabs
                                activeTab={this.state.activeTab}
                                changeTab={this.changeTab}
                                tabs={TABS}
                                lg
                            />
                        </div>
                    </div>
                    {this.state.activeTab === 'Students' &&
                        <RecruiterStudentSearch showPaywall={showPaywall}/>
                    }
                    {this.state.activeTab === 'Colleges' &&
                        <RecruiterCollegeSearch showPaywall={showPaywall}/>
                    }
                    {this.state.activeTab === 'Clubs' &&
                        <RecruiterClubSearch showPaywall={showPaywall}/>
                    }
                </div>
                {showPaywall &&
                    <RecruiterPaywall/>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterSearchPage);
