import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import JobBoardLoading from "../loading/JobBoardLoading";
import FadeInOnScroll from "../atoms/FadeInOnScroll";
import Spinner from "../Spinner";
import {SEARCH_STUDENT_TABLE_HEADERS, MAJOR_OPTIONS,} from "../../utils/dummy";
import {NUM_STUDENTS_SHOWN, CURRENT_STUDENT_COLLEGES} from "../../utils/constants";
import StudentSearchListItem from "../StudentSearchListItem";
import {filteredUserSearch} from "../../utils/typesense";
import SearchHeaderWrapper from "../../components-recruiter/atoms/SearchHeaderWrapper";
import RecruiterDashboardTable from "../../components-recruiter/RecruiterDashboardTable";
import {Button} from "../atoms/Button";
import SvgChevronUp from "../icons/ChevronUp";
import SvgChevronDown from "../icons/ChevronDown";
import SvgTrash from "../icons/SvgTrash";
import { createSavedSearch, deleteSearch, getSavedSearches } from "../../api/recruiter/saved_searches";
import SelectClubListModal from "../../components-recruiter/modals/SelectClubListModal";
import { pluralizeString } from "../../utils/strings";
import { object } from "prop-types";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        tier: state.userReducer.tier,
    };
}

const initialFilters = {
    classYears: [],
    majors: [],
    gpa: null,
    college: [],
    gender: null,
    race: null,
    veteran: null,
    lgbtq: null,
    disabled: null,
    authorized: null,
    isIb: null,
    search: "",
    sortBy: false,
    clubId: null,
    currentCompany: null,
    previousCompany: null,
    page: 1
};


class RecruiterStudentSearch extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            studentPreview: {},
            shortlisted: false,
            students: [],
            originalStudents: [],
            loading: true,
            moreStudentsLoading: false,
            studentPreviewOpen: false,
            grid: false,
            page: 1,
            hideMoreStudents: false,
            selectedCandidateId: null,
            isViewingSavedSearches: false,
            isSavingSearch: false,
            savedSearches: [],
            selectedStudents: {},
            isSelectListModalOpen: false,
            selectAllChecked: false,
            ...initialFilters
        };
    }

    previewStudent = (student) => {
        this.setState({studentPreview: student, studentPreviewOpen: true})
    }

    closeStudentPreview = () => {
        this.setState({studentPreviewOpen: false})
    }

    onSelectStudentChange = (student) => {
      if (this.state.selectedStudents[student.id]) {
        const { [student.id]: _, ...selectedStudents } = this.state.selectedStudents;
        this.setState({selectedStudents})
      } else {
        this.setState({selectedStudents: {...this.state.selectedStudents, [student.id]: student}})
      }
    }

    isStudentSelected = (studentId) => {
        return !!this.state.selectedStudents[studentId]
    }

    componentDidMount = async () => {
        this.setState({loading: true})

        const clubId = this.props.tier === "blk" ? "baeZVpmndkm8fCNGFIuZ" : null;
        const results = await filteredUserSearch(this.state.search, 1, clubId);
        this.setState({loading: false, students: results.students, originalStudents: results.students, clubId});

        const queryParameters = new URLSearchParams(window.location.search);
        const searchId = queryParameters.get('searchId', "");
        this.getSearches(searchId);

    }

    getSearches = async (searchId) => {
        const res = await getSavedSearches(this.props.authUser);
        this.setState({savedSearches: res.searches});

        if (!!searchId) {
            const items = res.searches.filter(search => search.id === searchId);
            if (items.length) {
                this.applySearch(items[0]);
            }
        }
    }

    handleSearchChange = (event) => {
        if (!this.props.showPaywall) {
            const text = event.target.value;
            this.setState({search: text});

            this.search(text, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
        }
    }

    updateClassYears = (year) => {
        if (year === null) {
            this.setState({classYears: []});
            this.search(this.state.search, [], this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
        this.search(this.state.search, newClassYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
    }

    updateMajor = (majors) => {
        if (majors === null) {
            this.setState({majors: []});
            this.search(this.state.search, this.state.classYears, [], this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
            return
        }

        let newMajors = [...this.state.majors];
        if (this.state.majors.includes(majors)) {
            const index = this.state.majors.indexOf(majors);
            newMajors.splice(index, 1);
        } else {
            newMajors.push(majors);
        }
        this.setState({majors: newMajors});
        this.search(this.state.search, this.state.classYears, newMajors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
    }

    updateGpa = (gpa) => {
        if (!this.props.showPaywall) {
            this.setState({gpa});

            this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
        }
    }

    updateSortBy = (sortBy) => {
        if (!this.props.showPaywall) {
            this.setState({sortBy});

            this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, this.state.clubId, sortBy);
        }
    }

    updateDiversity = (field, value) => {
        if (!this.props.showPaywall) {
            value = value ? value : null;
            this.setState({[field]: value});

            if (field === "gender") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, value);
            else if (field === "race") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, value);
            else if (field === "veteran") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, value);
            else if (field === "lgbtq") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, value);
            else if (field === "disabled") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, value);
            else if (field === "authorized") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, value);
            else if (field === "isIb") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, this.state.clubId, this.state.sortBy, this.state.companyId, this.state.previousCompany, value);
        }
    }

    updateCollege = (college) => {
        if (!this.props.showPaywall) {
            if (college === null) {
                this.setState({college: []});
                this.search(this.state.search, this.state.classYears, this.state.majors, [], this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
                return;
            }

            let newColleges = [...this.state.college];
            if (this.state.college.includes(college)) {
                const index = this.state.college.indexOf(college);
                newColleges.splice(index, 1);
            } else {
                newColleges.push(college);
            }
            this.setState({college: newColleges});
            this.search(this.state.search, this.state.classYears, this.state.majors, newColleges, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
        }
    }

    setSchool = (result) => {
        if (!result) {
            this.setState({page: 1})
        }
        this.setState({
            college: !!result ? [result] : []
        })
        this.search(this.state.search, this.state.classYears, this.state.majors, !!result ? [result] : [], this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
    }

    setClub = (result) => {
        if (!result) {
            this.setState({page: 1})
        }
        this.setState({
            clubId: result
        })
        this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, result);
    }

    setCurrentCompany = (companyId) => {
        this.setState({ currentCompany: companyId });
        this.search(
            this.state.search,
            this.state.classYears,
            this.state.majors,
            this.state.college,
            this.state.gpa,
            this.state.gender,
            this.state.race,
            this.state.veteran,
            this.state.lgbtq,
            this.state.disabled,
            this.state.authorized,
            this.state.clubId,
            this.state.sortBy,
            companyId,
            this.state.previousCompany
        );
    }

    setPreviousCompany = (companyId) => {
        this.setState({ previousCompany: companyId });
        this.search(
            this.state.search,
            this.state.classYears,
            this.state.majors,
            this.state.college,
            this.state.gpa,
            this.state.gender,
            this.state.race,
            this.state.veteran,
            this.state.lgbtq,
            this.state.disabled,
            this.state.authorized,
            this.state.clubId,
            this.state.sortBy,
            this.state.currentCompany,
            companyId
        );
    }

    search = async (
        text,
        classYears = [],
        majors = [],
        college = [],
        gpa = null,
        gender = null,
        race = null,
        veteran = null,
        lgbtq = null,
        disabled = null,
        authorized = null,
        clubId = this.state.clubId,
        sortBy = this.state.sortBy,
        currentCompany = this.state.currentCompany,
        previousCompany = this.state.previousCompany,
        isIb = this.state.isIb,
    ) => {
        if (
            text === "" &&
            !classYears.length &&
            !majors.length &&
            !college.length &&
            !gpa &&
            !gender &&
            !race &&
            !veteran &&
            !lgbtq &&
            !disabled &&
            !authorized &&
            !clubId &&
            !sortBy &&
            !currentCompany &&
            !previousCompany &&
            !isIb
        ) {
            this.setState({ students: this.state.originalStudents, hideMoreStudents: false, page: 1 });
            return;
        }
        window.analytics.track("Recruiter Student Search Performed", {
            query: text
        });

        const results = await filteredUserSearch(
            text,
            1,
            clubId,
            classYears,
            majors,
            college,
            gpa,
            gender,
            race,
            veteran,
            lgbtq,
            disabled,
            authorized,
            [],
            sortBy,
            currentCompany,
            previousCompany,
            isIb,
        );
        this.setState({
            students: results.students,
            loading: false,
            hideMoreStudents: results.students.length < NUM_STUDENTS_SHOWN,
            page: 1
        });
    };

    getNextStudentInfo = async () => {
        if (!this.props.showPaywall) {
            this.setState({moreStudentsLoading: true});
            const newStudents = await filteredUserSearch(
                this.state.search,
                this.state.page + 1,
                this.state.clubId,
                this.state.classYears,
                this.state.majors,
                this.state.college,
                this.state.gpa,
                this.state.gender,
                this.state.race,
                this.state.veteran,
                this.state.lgbtq,
                this.state.disabled,
                this.state.authorized,
                [],
                this.state.sortBy,
                this.state.currentCompany,
                this.state.previousCompany,
                this.state.isIb,
            );
            this.setState({
                students: [...this.state.students, ...newStudents.students],
                moreStudentsLoading: false,
                page: this.state.page + 1,
                hideMoreStudents: newStudents.students.length < NUM_STUDENTS_SHOWN,
            })
        }
    }

    clearFilters = () => {
        this.setState({...initialFilters});
        this.setState({students: this.state.originalStudents});
    }

    saveSearch = async () => {
        this.setState({isSavingSearch: true});
        const { classYears, majors, gpa, college } = this.state;
        const savedSearch = {
            grades: classYears,
            majors,
            gpa,
            college,
        };
        await createSavedSearch(this.props.authUser, savedSearch);
        this.setState({isSavingSearch: false})
    };

    toggleIsViewingSavedSearches = () => {
        this.setState({isViewingSavedSearches: !this.state.isViewingSavedSearches})
    }

    deleteSearch = async (searchId) => {
        await deleteSearch(this.props.authUser, searchId);
        this.toggleIsViewingSavedSearches();
        window.location.reload();
    }

    applySearch = (params) => {
        this.setState({isViewingSavedSearches: false});
        this.setState({
            college: params.college,
            classYears: params.grades,
            majors: params.majors,
            gpa: params.gpa,
            search: ""
        })
        this.search("", params.grades, params.majors, !!params.college ? [params.college] : [], params.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
    }

    toggleSelectAll = async () => {
        if (this.state.selectAllChecked) {
            this.setState({selectAllChecked: !this.state.selectAllChecked, selectedStudents: []});
            return;
        }

        let selectedStudents = {...this.state.selectedStudents};
        for (let i = 0; i < 10; i += 1) {
            const newStudents = await filteredUserSearch(this.state.search, i, this.state.clubId, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, [],
                this.state.sortBy,
                this.state.currentCompany,
                this.state.previousCompany,
                this.state.isIb);
            if (newStudents.students.length === 0) break;

            let formattedNewStudents = {};
            newStudents?.students.forEach(obj => {
                formattedNewStudents[obj.id] = obj;
            });
            Object.assign(selectedStudents, formattedNewStudents);
        }
        this.setState({selectAllChecked: !this.state.selectAllChecked, selectedStudents})
    }

    render() {
        const canClearFilters =
            this.state.classYears.length > 0 ||
            this.state.majors.length > 0 ||
            this.state.college.length > 0 ||
            this.state.gpa !== null ||
            this.state.gender !== null ||
            this.state.race !== null ||
            this.state.veteran !== null ||
            this.state.lgbtq !== null ||
            this.state.disabled !== null ||
            this.state.authorized !== null ||
            this.state.search !== "" ||
            this.state.sortBy ||
            this.state.clubId !== null ||
            this.state.currentCompany !== null ||
            this.state.previousCompany !== null ||
            this.state.isIb;

        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <SearchHeaderWrapper
                    search={{
                        placeholder: 'Search by name...',
                        value: this.state.search,
                        onChange: this.handleSearchChange,
                    }}
                    updateDiversity={this.updateDiversity}
                    setSchool={this.setSchool}
                    setClub={this.props.tier !== "blk" ? this.setClub: null}
                    schoolSearch={this.state.college}
                    clearFilters={canClearFilters ? this.clearFilters : undefined}
                    selects={[
                        {
                            label: 'Major',
                            value: this.state.majors,
                            setValue: this.updateMajor,
                            options: MAJOR_OPTIONS,
                            multiSelect: true,
                        },
                        {
                            label: 'Class Year',
                            value: this.state.classYears,
                            setValue: this.updateClassYears,
                            options: [{title: "2025",}, {title: "2026",}, {title: "2027",}, {title: "2028",}],
                            multiSelect: true,
                        },
                        {
                            label: 'Min GPA',
                            value: this.state.gpa,
                            setValue: this.updateGpa,
                            options: [{title: "3.9",}, {title: "3.8",}, {title: "3.7",}, {title: "3.6",}, {title: "3.5",}, {title: "3.4",}, {title: "3.3",}, {title: "3.2",}, {title: "3.1",}, {title: "3.0",}],
                        },
                        {
                            label: 'Gender',
                            value: this.state.gender,
                            setValue: this.updateDiversity,
                            options: [{title: "Male",}, {title: "Female",}, {title: "Prefer Not to Specify",}],
                            field: 'gender'
                        },
                        {
                            label: 'Race',
                            value: this.state.race,
                            setValue: this.updateDiversity,
                            options: [{title: "White",}, {title: "Hispanic or Latino",}, {title: "Black or African American",}, {title: "American Indian or Native American",},  {title: "Asian or Pacific Islander",}, {title: "Other",}, {title: "Prefer Not to Specify",}],
                            field: 'race'
                        },
                    ]}
                    checkboxes={[
                        {
                            label: 'Is IB',
                            active: this.state.isIb,
                            onClick: () => this.updateDiversity("isIb", !this.state.isIb)
                        },
                        {
                            label: 'Veteran',
                            active: this.state.veteran,
                            onClick: () => this.updateDiversity("veteran", !this.state.veteran)
                        },
                        {
                            label: 'LGBTQ',
                            active: this.state.lgbtq,
                            onClick: () => this.updateDiversity("lgbtq", !this.state.lgbtq)
                        },
                        {
                            label: 'Disability',
                            active: this.state.disabled,
                            onClick: () => this.updateDiversity("disabled", !this.state.disabled)
                        },
                        {
                            label: 'Authorized to work',
                            active: this.state.authorized,
                            onClick: () => this.updateDiversity("authorized", !this.state.authorized)
                        },
                        {
                            label: 'Sort by name',
                            active: this.state.sortBy,
                            onClick: () => this.updateSortBy(!this.state.sortBy)
                        },
                    ]}
                    setCurrentCompany={this.setCurrentCompany}
                    setPreviousCompany={this.setPreviousCompany}
                >
                </SearchHeaderWrapper>
                <div className='my-2'>
                    <div className='flex flex-row items-center justify-end gap-4'>
                        <p className='text-sm text-slate-500'>
                            {Object.values(this.state.selectedStudents).length} {pluralizeString('student', Object.values(this.state.selectedStudents).length)} selected
                        </p>
                        <Button
                            size="sm"
                            onClick={() => this.setState({isSelectListModalOpen: true})}
                            disabled={Object.values(this.state.selectedStudents).length === 0}
                        >
                           Add to list
                        </Button>
                    </div>
                </div>
                <RecruiterDashboardTable
                    columns={SEARCH_STUDENT_TABLE_HEADERS}
                    hasCheckbox={true}
                    checked={this.state.selectAllChecked}
                    toggleCheckbox={this.toggleSelectAll}
                    TableBody={
                        <div className='flex-1 flex flex-col mt-0 pt-2 gap-1 p-5 overflow-y-scroll'>
                            {this.state.loading ?
                                <JobBoardLoading/>
                                :
                                this.state.students.length === 0 ?
                                    <div
                                        className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                        <SvgSearch/>
                                        <p className='body2 mt-2 mb-6'>
                                            No students found...
                                        </p>
                                    </div>
                                    :
                                    <>
                                        {this.state.students.map((item, index) =>
                                            <FadeInOnScroll key={item.id} triggerImmediately
                                                            delay={(index * 50) + 10}>
                                                <StudentSearchListItem
                                                    key={item.id}
                                                    item={item}
                                                    previewStudent={this.previewStudent}
                                                    previewingID={this.state.studentPreview.id}
                                                    columns={SEARCH_STUDENT_TABLE_HEADERS}
                                                    onSelectStudentChange={this.onSelectStudentChange}
                                                    isSelected={this.isStudentSelected(item.id)}
                                                />
                                            </FadeInOnScroll>
                                        )}
                                    </>
                            }
                            {(!this.state.hideMoreStudents && (!this.state.loading && this.state.students.length !== 0)) &&
                                <div className='flex flex-row items-center justify-center mr-3 py-8 mb-20 body2-bold'
                                     onClick={this.getNextStudentInfo}>
                                    <Button loading={this.state.moreStudentsLoading} variant={'ghost'}>
                                        Load more students
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                />
                <SelectClubListModal
                    items={Object.values(this.state.selectedStudents)}
                    type={"user"}
                    isOpen={this.state.isSelectListModalOpen}
                    authUser={this.props.authUser}
                    closeModal={() => this.setState({isSelectListModalOpen: false})}
                    onUpdate={() => this.setState({selectedStudents: {}})}
                />

                <div
                    className={`fixed bottom-12 left-1/2 ${this.state.isViewingSavedSearches ? 'h-auto' : 'h-0 overflow-hidden'}`}>
                    <div
                        className='-translate-x-1/2 bg-white min-w-[30vw] p-[12px] flex flex-col gap-2 border w-full border-slate-200 shadow-xl absolute bottom-10 rounded-xl'>
                        <p className='text-base font-semibold'>
                            Saved Searches
                        </p>
                        <div className='flex flex-col gap-2 max-h-[30vh] overflow-y-scroll'>
                            {this.state.savedSearches.length === 0 ?
                                <div className='flex flex-col items-center justify-center p-5'>
                                    <p className='text-slate-500'>
                                        No saved searches yet
                                    </p>
                                </div>
                                : this.state.savedSearches.map((item, index) =>
                                    <div key={index}
                                         onClick={() => this.applySearch(item)}
                                         className='border flex flex-row items-center justify-between border-slate-200 hover:border-slate-300 hover:bg-slate-50 cursor-pointer rounded-lg p-3 text-sm '>
                                        <div>
                                            {!!item.grades.length && <p><strong>Class Years:</strong> {item.grades.join(", ")}</p>}
                                            {!!item.majors.length && <p><strong>Majors:</strong> {item.majors.join(", ")}</p>}
                                            {!!item.gpa && <p><strong>GPA:</strong> {item.gpa !== null ? item.gpa : "N/A"}</p>}
                                            {!!item.college && <p><strong>College:</strong> {item.college}</p>}
                                        </div>
                                        <div className='flex flex-row gap-2 ml-auto'>
                                            <Button variant='ghost' onClick={() => this.deleteSearch(item.id)} icon={SvgTrash}/>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className='fixed bottom-8 left-1/2 -translate-x-1/2'>
                    <div className='flex flex-row gap-2 items-center relative'>
                        <Button onClick={this.toggleIsViewingSavedSearches} variant='secondary' className='mr-3'>
                            Saved searches <SvgChevronDown
                            className={`${this.state.isViewingSavedSearches ? 'rotate-[180deg]' : 'rotate-0'} duration-150`}/>
                        </Button>
                        {(this.state.classYears?.length > 0 || this.state?.gpa !== null || this.state.majors?.length > 0 || this.state.college?.length > 0) &&
                            <Button onClick={this.saveSearch} loading={this.state.isSavingSearch} className='mr-3'>
                                Save this search
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterStudentSearch);
