import './styles/Formatting.css';
import './styles/ColorStyles.css'
import './styles/Inputs.css'
import './styles/Navbar.css'
import './styles/TextStyles.css'
import './styles/ButtonStyles.css'
import './styles/Components.css'
import './styles/Gallery.css'
import './styles/ModalComponent.css'
import './styles/tailwinds-import.css'
import './styles/Radix.css'
import "react-day-picker/dist/style.css";
import {BrowserRouter as Router} from 'react-router-dom';
import {Component} from "react";
import Routes from "./Routes";
import UserProvider, {UserContext} from "./providers/UserProvider";
import {Provider} from "react-redux";
import Store from "./Store";
import "react-datepicker/dist/react-datepicker.css";
import {Helmet} from 'react-helmet';
import { segmentKey } from './api/axios';

import { pdfjs } from 'react-pdf';
import ToastComponent from './components/atoms/ToastComponent';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class App extends Component {
    state = {
        mobile: false,
        tablet: false,

    }

    componentDidMount() {
        this.resize()
        window.addEventListener("resize", this.resize.bind(this));
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth > 760 && window.innerWidth <= 1200)
        });
    }

    render() {
        return (
            <div className='relative col-plain' style={{height: '100vh'}}>
                {/* Load Segment script */}
                <Helmet>
                    <script>
                    {`
                          !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="${segmentKey}";;analytics.SNIPPET_VERSION="5.2.0";
                          analytics.load("${segmentKey}");
                          analytics.page();
                          }}();
                    `}
                    </script>
                    <script type="text/javascript">
                    {`
                        window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
                        heap.load("${process.env.REACT_APP_HEAP_KEY}");
                    `}
                    </script>
                </Helmet>
                <UserProvider>
                    <Router>
                        <Provider store={Store}>
                            <UserContext.Consumer>
                                {(user) => (
                                    <Routes user={user} mobile={this.state.mobile}/>
                                )}
                            </UserContext.Consumer>
                        </Provider>
                    </Router>
                    <ToastComponent/>
                </UserProvider>
                {/*{!(this.state.mobile || this.state.tablet) &&*/}
                {/*    <WalkThroughModal isOpen={true}/>*/}
                {/*}*/}
            </div>
        )
    }
}

export default App

