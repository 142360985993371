import React from "react";
import SvgNoteAdd from "../../../components/icons/SvgNoteAdd";
import NoteComponent from "../../../components/NoteComponent";
import { Button } from "../../../components/atoms/Button";
import { SIDEBAR_CARD } from "../../../utils/dummy";

const RecruiterStudentNotes = ({
    notes,
    deleteNote,
    noteChanged,
    newNote,
    addNote
}) => {
    return (
        <div className="flex-1 overflow-y-scroll gap-5 pt-3">
            <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                <p className="text-slate-800 text-lg font-semibold">
                    Notes
                </p>
                {notes.length === 0 ? (
                    <div className="flex flex-col items-center justify-center py-7 bg-slate-50 rounded-lg">
                        <div className="flex flex-col items-center cursor-pointer text-neutral-400 hover:text-neutral-700">
                            <SvgNoteAdd className={"w-8 h-8 mb-2"} />
                        </div>
                        <p className="text-xs text-slate-400">
                            No notes yet...
                        </p>
                    </div>
                ) : (
                    <div>
                        {notes.map((note, index) => (
                            <NoteComponent
                                note={note}
                                deleteNote={deleteNote}
                                index={index}
                            />
                        ))}
                    </div>
                )}
                <div className="flex flex-col">
                    <div className="flex flex-row flex-1 items-center gap-2">
                        <textarea
                            id="message"
                            className=" px-2 text-sm py-0.5 text-sm flex flex-1 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Enter private notes for your recruiting team..."
                            onChange={noteChanged}
                            value={newNote}
                        />
                        <Button
                            onClick={addNote}
                            disabled={!newNote}
                            className="primary-button body1-bold"
                        >
                            Add note
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecruiterStudentNotes;
