import React from 'react';
import { SIDEBAR_CARD } from '../../../utils/dummy';

const InfoSection = ({ label, value }) => {
    if (!value) return null;
    return (
        <div className='flex flex-col rounded-lg p-4'>
            <p className='text-sm text-slate-500'>{label}</p>
            <p className='text-md font-semibold text-slate-800'>
                {Array.isArray(value) ? value.join(', ') : value}
            </p>
        </div>
    );
};

const RecruiterStudentInfo = ({ student }) => {
    const diversity = student?.diversity || {};

    return (
        <div className='flex-1 overflow-y-scroll pt-3'>
            <div className={`flex flex-col gap-3 ${SIDEBAR_CARD}`}>
                <p className='text-slate-800 text-lg font-semibold'>
                    Candidate Information
                </p>

                <InfoSection label='Gender' value={diversity.gender} />
                <InfoSection label='Race/Ethnicity' value={diversity.race} />
                <InfoSection label='Veteran Status' value={diversity.veteran} />
                <InfoSection label='Disability Status' value={diversity.disabled} />
                <InfoSection label='First Generation College Student' value={diversity.first_generation} />
                <InfoSection label='LGBTQ+' value={diversity.lgbtq} />
                <InfoSection label='Work Authorization' value={diversity.authorized} />
                <InfoSection label='Sponsorship' value={diversity.require_sponsorship} />

                {!Object.keys(diversity).length && (
                    <div className='flex flex-col items-center py-10'>
                        <p className='text-xs text-slate-400'>
                            No additional candidate information available
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RecruiterStudentInfo;
