import axios from '../axios';

export const getCompany = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/recruiter/companies", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getCompanyStats = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/recruiter/companies/stats", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editCompany = async (user, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
        }
        const res = await axios.put("/recruiter/companies", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const inviteRecruiter = async (user, email) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            user_id: user.id,
            email,
        }
        const res = await axios.put("/recruiter/companies/invite", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const emailUsers = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.put("/recruiter/companies/email", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getCompanyEmails = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/recruiter/companies/email", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getCompanyUserEmails = async (user, userId) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {
                user_id: userId,
            },
        };
        const res = await axios.get("/recruiter/companies/email/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}