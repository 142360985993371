import React, {useState} from "react";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingStepWrapper from "./OnboardingStepWrapper";
import {Button} from "../atoms/Button";
import {uploadDoc} from "../../api/firebase";
import Dropzone from "react-dropzone";
import SvgPostAdd from "../icons/SvgPostAdd";
import SvgStars from "../icons/SvgStars";
import {v4 as uuid} from 'uuid';
import SvgInfo from "../icons/SvgInfo";
import useIsMobile from "../../hooks/isMobile";

function OnboardingResumeStep({goNextStep, resume, setResume, user}) {
    const [buttonMessage, setButtonMessage] = useState("Select a file or drag and drop your resume here");
    const isMobile = useIsMobile();

    const onDrop = async (acceptedFiles) => {
        setButtonMessage("Resume uploaded!");
        const newUuid = await uuid();
        const url = await uploadDoc("resumes", newUuid, acceptedFiles[0]);
        setResume(url);
        goNextStep();
    };

    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={"Upload your resume"}
                description={"Upload the resume you want recruiters to see, you can always update this"}
            />
            <div className='w-full '>
                <Dropzone onDrop={onDrop} accept={{
                        'image/png': ['.png', '.jpeg'],
                        'text/html': ['.pdf'],
                    }}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="cursor-pointer">
                            <input {...getInputProps()} />
                            <div
                                className="w-full flex flex-col gap-3 items-center justify-center rounded-xl h-40 md:h-52 border border-slate-300 border-dashed px-6 text-slate-500 hover:bg-slate-50">
                                <SvgPostAdd className={'w-8 h-8'}/>
                                <p className="text-slate-500 text-sm ml-2">{buttonMessage}</p>
                                {!resume &&
                                    <Button variant={'secondary'} size={'sm'}>
                                        Select file
                                    </Button>
                                }
                            </div>
                        </div>
                    )}
                </Dropzone>
                <div className='flex flex-row md:items-center justify-center text-primary gap-1 text-sm mt-2'>
                    <SvgInfo className={'w-4 h-4 mt-.5 md:mt-0'}/>
                    <p className=''>
                        A resume is essential for recruiters looking to find candidates.
                    </p>
                </div>
                {(!user?.clubs?.length || isMobile) ? 
                    <div className='flex flex-col items-center'>
                        <Button variant={"ghost"} className="mx-auto mt-20 md:mt-[80px]" size={"lg"} onClick={() => goNextStep()}>
                            Skip for now
                        </Button>
                    </div>
                :
                null}
            </div>
        </OnboardingStepWrapper>
    );
}

export default OnboardingResumeStep;
