import React, { useRef, useState } from "react";
import { Button } from "../../components/atoms/Button";
import { uploadDoc } from "../../api/firebase";
import { toast } from "react-toastify";
import SvgDocument from "../../components/icons/SvgDocument";


const RecruiterUploadPdf = ({ type, onUpload, jobPdfPath, id }) => {
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        
        if (file.type !== "application/pdf") {
            toast.error("Please upload a PDF file");
            return;
        }
        
        setLoading(true);
        
        try {
            await uploadDoc(type, id, file);
            const path = `${type}/${id}`;
            onUpload(path);
            toast.success("PDF uploaded successfully");
        } catch (error) {
            toast.error("Failed to upload PDF");
            console.error("Upload error:", error);
        } finally {
            setLoading(false);
            event.target.value = "";
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div>
            <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
            />

            <Button
                loading={loading}
                onClick={handleButtonClick}
                className="w-full"
                variant={'secondary'}
            >
                <div className="flex items-center justify-center gap-1">
                    {jobPdfPath && (
                        <SvgDocument className="w-4 h-4 text-primary-400" />
                    )}
                    {jobPdfPath ? "Replace PDF" : "Upload PDF"}
                </div>
            </Button>
        </div>
    );
};

export default RecruiterUploadPdf;
