import React, {PureComponent} from "react";
import TagComponent from "../../components/atoms/TagComponent";
import SvgPin from "../../components/icons/SvgPin";
import SvgBriefcase from "../../components/icons/SvgBriefcase";
import SvgArrow from "../../components/icons/SvgArrow";
import {Link} from "react-router-dom";
import RecruiterPeekEditStatus from "../modals/RecruiterPeekEditStatus";
import RecruiterSideStatusDisplay from "../atoms/RecruiterSideStatusDisplay";
import {connect} from "react-redux";
import {toHaveStyle} from "@testing-library/jest-dom/dist/matchers";
import {DateTime} from 'luxon';

function mapDispatchToProps(dispatch) {
    return {
        setApplicationPeekOpen: (application) => {
            dispatch({
                type: "SET_APPLICATION_PEEK_OPEN",
                application,
            })
        },
    }
}



class RecruiterStudentPeekApplicationItem extends PureComponent {

    handleOpenApplicationPeek = (e) => {
        e.stopPropagation();
        const payload = {
            application: this.props.application,
            studentData: this.props.student
        }
        console.log(payload);
        this.props.setApplicationPeekOpen(payload);
    }

    render() {
        const application = this.props.application || {};
        const answers = application.answers || {};
        const job = application.job || {};
        const appliedDate = !!application.created_at ? DateTime.fromISO(application.created_at) : null;

        return (
            <div className='flex flex-col bg-slate-50 rounded-lg p-3'>
                <div className='flex flex-row gap-4 items-center'>
                    {appliedDate && (
                        <div>
                            <div
                                className='flex flex-col items-center rounded-lg border-neutral-200 bg-white border w-11 overflow-hidden'>
                                <p className='body4-bold uppercase p-0.5 px-2 bg-neutral-100 w-full text-center'>
                                    {appliedDate.toFormat("MMM")}
                                </p>
                                <p className='body2-bold p-0.5'>
                                    {appliedDate.toFormat("d")}
                                </p>
                            </div>
                        </div>
                    )}
                    <div>
                        <div className={'flex flex-row items-center gap-3'}>
                            {(!!job.class_years && job.class_years.length > 0) &&
                                <div className='flex flex-row text-primary items-center gap-1.5'>
                                    <SvgBriefcase className={'w-3.5 h-3.5'}/>
                                    <p className='text-sm text-primary font-semibold'>
                                        {!!job.class_years && job.class_years.length ? "Class of " + job.class_years.map(year => "'" + year.slice(2)) : null}
                                    </p>
                                </div>
                            }
                        </div>
                        <p
                            className='text-base font-semibold text-slate-800 group-hover:text-primary cursor-pointer'>
                            {job.name}
                        </p>
                        <div className='mr-a mt-1 flex flex-row items-center gap-2 wrap'>
                            <TagComponent label={job.type}/>
                            <TagComponent label={job.industry}/>
                            <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                                {!!job.city.length && !!job.state.length ?
                                    <div className='flex flex-row items-center gap-1.5'>
                                        <SvgPin className={'w-3.5 w-3.5'}/>
                                        <p className='text-sm '>
                                            {job.city[0]}, {job.state[0]}
                                        </p>
                                    </div>
                                    : null}
                                {!!job.openings ?
                                    <>
                                        <p>
                                            •
                                        </p>
                                        <p className='text-md '>
                                            <span className=''>{job.openings}</span> openings
                                        </p>
                                    </>
                                    : null}
                            </div>
                            <p className='text-sm text-slate-500'>&nbsp;Applied: {appliedDate.toFormat("D")}</p>
                        </div>
                        
                    </div>
                </div>
                {job.application_questions.length > 0 && (
                    <div className="mt-16">
                        {job.application_questions.map((question, i) => {
                            const answer = !!application.answers ? application.answers[i] : "N/A"
                            return (
                                <div className='flex flex-col mb-2'>
                                    <p className='text-xs font-semibold'>
                                        {i + 1 + ". "} {question.text}
                                    </p>
                                    <p className='text-xs text-semibold line-clamp-4'>
                                        {answer}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                )}
                {job.application_questions && job.application_questions.length > 0 && (
                    <div className="mt-4">
                        <button 
                            className="text-primary hover:text-primary-dark font-semibold text-sm flex items-center gap-1"
                            onClick={this.handleOpenApplicationPeek}
                        >
                            View Application <SvgArrow className={'inline w-4 h-4 mb-0.5'} />
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(RecruiterStudentPeekApplicationItem);