import React, {useState, useEffect} from "react";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {connect} from "react-redux";
import { editUser, getUser } from "../../api/student/users";
import { createApplication } from "../../api/student/applications";
import { createTracker } from "../../api/student/trackers";
import OnboardingLeftPane from "./OnboardingLeftPane";
import OnboardingCitiesStep from "./OnboardingCitiesStep";
import OnboardingLinkedinStep from "./OnboardingLinkedinStep";
import OnboardingResumeStep from "./OnboardingResumeStep";
import OnboardingEducationStep from "./OnboardingEducationStep";
import OnboardingIndustriesStep from "./OnboardingIndustriesStep";
import OnboardingCompaniesStep from "./OnboardingCompaniesStep";
import OnboardingMatchesStep from "./OnboardingMatchesStep";
import OnboardingWorkStep from "./OnboardingWorkStep";
import { addLinkedinUrl } from "../../api/student/users";
import SvgR from "../icons/SvgR";
import AnimatedLogo from "../atoms/loading/AnimatedLogo";
import Marquee from "react-fast-marquee";
import SvgLogo from "../icons/SvgLogo";
import { withRouter } from 'react-router';
import useIsMobile  from "../../hooks/isMobile";
import { getFlowSteps, ONBOARDING_STEPS } from "./onboarding-utils";
import { formatGpa } from '../../utils/number';
// import OnboardingEmailVerificationStep from "./OnboardingEmailVerificationStep";
// import { useLocation } from 'react-router-dom';

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        constants: state.userReducer.constants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

function OnboardingMain({user, authUser, setUser, constants, history}) {
    const [step, setStep] = useState(ONBOARDING_STEPS.EDUCATION);
    const [loading, setLoading] = useState(false);
    const [path, setPath] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [major, setMajor] = useState([]);
    const [minor, setMinor] = useState([]);
    const [gpa, setGpa] = useState(null);
    const [grade, setGrade] = useState("");
    const [graduationSeason, setGraduationSeason] = useState("");
    const [resume, setResume] = useState("");
    const [locations, setLocations] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [roleInterests, setRoleInterests] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [current_job, setCurrentJob] = useState({});
    const [clubs, setClubs] = useState([]);
    const have_job = user?.have_job;
    const job_status = user?.job_status;

    /**
     * Email verification flow is currently disabled but may be re-enabled in future
     * When re-enabling:
     * 1. Uncomment email verification component import
     * 2. Uncomment email verification code block below
     * 3. Add email verification step to onboarding flow and change the initial step to 4
     * @todo Reenable or clean the code up
     */

    /* 

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isEmailVerificationCallback = searchParams.get('email-verification') === 'true';
    const shouldSkipEmailVerification = user.email_verified && !isEmailVerificationCallback;

    useEffect(() => {
        if (shouldSkipEmailVerification) {
            setStep(5);
        }
    }, [shouldSkipEmailVerification]);

    End of email verification code

    */

    const isMobile = useIsMobile();
    const currentFlow = getFlowSteps(isMobile, user?.have_job, user?.job_status);

    const onEducationSubmit = (formData) => {
        setMajor(formData.major);
        setMinor(formData.minor);
        setGpa(!!formData.gpa ? formatGpa(formData.gpa) : 0.0);
        setGrade(formData.classYear);
        setClubs(formData.clubs);
        setGraduationSeason(formData.graduationSeason);
    }

    function goNextStep() {
        window.analytics.track("Onboarding Step Completed", {
          onboarding_step: step
        })
        
        if (step === currentFlow[currentFlow.length - 1]) {
            updateUser();
        } else {
            const currentIndex = currentFlow.indexOf(step);
            setStep(currentFlow[currentIndex + 1]);

            setQueryParams(currentIndex);
        }
    }

    const setQueryParams = (currentIndex) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("step", currentFlow[currentIndex + 1]);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        history.replace(newUrl);
    }

    function goPreviousStep() {
        const currentIndex = currentFlow.indexOf(step);
        if (currentIndex > 0) {
            setStep(currentFlow[currentIndex - 1]);
        }
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const path = queryParameters.get("path", "");
        setPath(path);
    }, [])

    const updateUser = async () => {
        setLoading(true);
        const linkedinUrl = linkedin.startsWith('http://') ? linkedin.replace('http://', 'https://') : linkedin;
        const newParams = {
            grade,
            linkedin: linkedinUrl,
            major,
            minor,
            gpa,
            graduation_season: graduationSeason,
            resume,
            locations,
            industries,
            role_interests: roleInterests,
            current_job,
            seeking_role: job_status !== "Not looking right now",   
            onboarded: true,
            clubs,
        }

        await editUser(authUser, newParams);
        await addLinkedinUrl(authUser, linkedin);
        for (const companyId of companies) {
            createTracker(authUser, {type: "company", company_id: companyId});
        }
        for (const job of jobs) {
            createApplication(authUser, {company_id: job.company_id, job_id: job.id});
        }

        const user = await getUser(authUser);
        setPath(path);
        setUser(user.user);
        setLoading(false);

        window.analytics.track("Onboarding Completed")
    }

    if (loading) {
        return <div className='min-h-[100vh] flex flex-col items-center justify-center'>
            <div className='flex flex-col items-center'>
                <div className='flex flex-row items-center scale-[.5]'>
                    <SvgR className={'-mr-1 mb-[2px] text-slate-900'}/>
                    <AnimatedLogo width={62} height={62} loading="true"/>
                </div>
                <Marquee direction={'right'} className='bg-slate-200 rounded-full mr-1' style={{width: 52}}>
                    <div className='h-2 w-2 rounded-full bg-slate-500'/>
                </Marquee>
            </div>
        </div>
    }
    return (
        <div className='flex flex-row flex-1  overflow-hidden bg-student-gradient md:bg-unset'>
            <OnboardingLeftPane step={step}/>
           
            <div className='flex flex-col flex-1 md:overflow-y-scroll md:mt-[72px] justify-center md:justify-start w-full mx-10 md:mx-0 overflow-hidden md:overflow-auto'>
                <div className='flex flex-col gap-0 items-center mb-7 md:hidden'>
                    <SvgLogo className={'w-56 text-white'}/>
                </div>
                <SwitchTransition>
                    <CSSTransition
                        key={step}
                        timeout={500}
                        classNames="fade-slide"
                    >
                        <div className="w-full bg-white tw-card md:tw-card-none max-h-[calc(100vh-7rem)] md:max-h-full overflow-y-auto flex mx-auto md:block">

                        

                            {/* Email verification is currently disabled */}
                            {/* {step === 4 && !shouldSkipEmailVerification && (
                                <OnboardingEmailVerificationStep
                                    goNextStep={goNextStep}
                                    authUser={authUser}
                                    verificationComplete={user.email_verified && isEmailVerificationCallback}
                                />
                            )} */}

                            {/* Onboarding starts here */}

                            {step === ONBOARDING_STEPS.EDUCATION && (
                                <OnboardingEducationStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    onEducationSubmit={onEducationSubmit}
                                    haveJob={have_job}
                                />
                            )}

                           
                            {step === ONBOARDING_STEPS.RESUME && (
                                <OnboardingResumeStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    resume={resume}
                                    setResume={setResume}
                                    user={user}
                                />
                            )}

                            {step === ONBOARDING_STEPS.LINKEDIN && (
                                <OnboardingLinkedinStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    linkedin={linkedin}
                                    setLinkedin={setLinkedin}
                                    lastStep={step === currentFlow[currentFlow.length - 1]}
                                />
                            )}
                           
                            {/*Work step to show after education step IF the user said they had a job*/}
                            {step === ONBOARDING_STEPS.WORK && (
                                <OnboardingWorkStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    setCurrentJob={setCurrentJob}
                                    jobStatus={job_status}
                                    lastStep={step === currentFlow[currentFlow.length - 1]}
                                />
                            )}

                            {/*Preferences, skip this if user said they are not looking for a job*/}
                            {step === ONBOARDING_STEPS.CITIES && (
                                <OnboardingCitiesStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    onCitiesSelected={setLocations}
                                    cities={locations}
                                />
                            )}
                            {step === ONBOARDING_STEPS.INDUSTRIES && (
                                <OnboardingIndustriesStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    onIndustriesSelected={setIndustries}
                                    onRolesSelected={setRoleInterests}
                                    industries={industries}
                                    roles={roleInterests}
                                    constants={constants}
                                />
                            )}
                            {step === ONBOARDING_STEPS.COMPANIES && (
                                <OnboardingCompaniesStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    onCompaniesSelected={setCompanies}
                                />
                            )}
                            {/*Matches*/}
                            {step === ONBOARDING_STEPS.MATCHES && (
                                <OnboardingMatchesStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    jobs={jobs}
                                    setJobs={setJobs}
                                    grade={grade}
                                    industries={industries}
                                    companies={companies}
                                />
                            )}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </div>
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingMain));
