import React, {useEffect, useMemo, useState} from "react";
import SvgChevronLeft from "../icons/SvgChevronLeft";
import {connect} from "react-redux";
import EditProfileEducation from "./EditProfileEducation";
import EditProfileResumeLinkedin from "./EditProfileResumeLinkedin";
import EditProfileLocation from "./EditProfileLocation";
import EditProfileRoles from "./EditProfileRoles";
import EditProfileCompanies from "./EditProfileCompanies";
import EditProfileEqualEmployment from "./EditProfileEqualEmployment";
import EditProfileSponsorship from "./EditProfileSponsorship";
import {useHistory} from "react-router-dom";
import {getSectionStatus, sections} from "../../utils/profileUtils";
import EditProfileDetails from "./EditProfileDetails";
import EditProfileExperienceDetails from "./EditProfileExperienceDetails";
import {addLinkedinUrl, getUser} from "../../api/student/users";
import {toast} from "react-toastify";
import {editUser} from "../../api/student/users";
import {DateTime} from "luxon";
import {canResyncLinkedIn} from "../../utils/profileUtils";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import SvgWarning from "../icons/SvgWarning";
import SvgUpdate from "../icons/SvgUpdate";
import Spinner from "../Spinner";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        constants: state.userReducer.constants,
        trackers: state.userReducer.trackers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

const EditProfilePage = ({user, authUser, constants, trackers, setUser}) => {
    const [activeSection, setActiveSection] = useState(sections[0].id);
    const history = useHistory();
    const profileSectionStatus = useMemo(() => {
        return sections.reduce((acc, section) => {
            acc[section.id] = getSectionStatus(section.id, user, trackers);
            return acc;
        }, {});
    }, [user, trackers]);

    const [loadingMap, setLoadingMap] = useState(() => {
        return sections.reduce((acc, section) => {
            acc[section.id] = false;
            return acc;
        }, {});
    });

    const {ableToResync, resyncMessage} = canResyncLinkedIn(user);

    const findNextSection = (currentSection) => {
        return sections.find(
            (section) => (profileSectionStatus[section.id] === "incomplete" || profileSectionStatus[section.id] === "update")
                && (!currentSection || section.id !== currentSection)
        );
    }

    const hasNextSection = () => {
        return !!findNextSection(activeSection);
    }

    // on first render, we jump to the first section that is incomplete
    useEffect(() => {
        if (!!findNextSection()) {
            setActiveSection(findNextSection().id);
        }
    }, []);

    // we update the profile_updated_at timestamp when the user views a section that needs updating
    useEffect(() => {
        if (profileSectionStatus[activeSection] === "update") {
            const updateProfileTimestamp = async () => {
                try {
                    await editUser(authUser, {
                        profile_updated_at: DateTime.now().toISO().replace('Z', '')
                    });
                    const {user: updatedUser} = await getUser(authUser);
                    setUser(updatedUser);
                } catch (error) {
                    console.error('Failed to update profile timestamp:', error);
                }
            };
            updateProfileTimestamp();
        }
    }, [activeSection, profileSectionStatus, authUser, setUser]);


    const handleSectionClick = (sectionId) => {
        setActiveSection(sectionId);
    };

    const navigate = (path) => {
        history.push(path);
    }

    const goToNextSection = () => {
        const nextIncomplete = findNextSection(activeSection);

        if (nextIncomplete) {
            setActiveSection(nextIncomplete.id);
        } else {
            navigate("/profile");
        }
    };

    const handleSetLoading = (section, loading) => {
        setLoadingMap(prev => ({...prev, [section]: loading}));
    }

    const handleResync = async (section, url) => {
        let linkedinUrl = url || user.linkedin;
        if (!linkedinUrl) {
            toast.error("You don't have a LinkedIn profile linked yet.");
            return;
        }
        if (!ableToResync) {
            toast.warn(`You can only resync your LinkedIn profile once every month. You can resync in ${resyncMessage}.`);
            return;
        }
        linkedinUrl = linkedinUrl.startsWith('http://') ? linkedinUrl.replace('http://', 'https://') : linkedinUrl;

        async function syncLinkedin() {
            try {
                handleSetLoading(section, true);
                await addLinkedinUrl(authUser, linkedinUrl);
                await editUser(authUser, {
                    linkedin: linkedinUrl
                });
                const userResult = await getUser(authUser);
                setUser(userResult.user);
            } catch (error) {
                throw error;
            } finally {
                handleSetLoading(section, false);
            }
        }

        toast.promise(syncLinkedin, {
            pending: "Syncing LinkedIn profile...",
            success: "LinkedIn profile synced successfully!",
            error: "Failed to sync LinkedIn profile. Please try again.",
        });
    }

    const renderStatusIcon = (status, loading) => {
        if (loading) {
            return <div className={'w-5 h-5'}><Spinner/></div>;
        }
        switch (status) {
            case "complete":
                return <SvgCheckCircle className={'w-5 h-5 text-primary'}/>;
            case "incomplete":
                return <SvgWarning className={'w-5 h-5 text-orange-500'}/>;
            case "update":
                return <SvgUpdate className={'w-5 h-5 text-yellow-500'}/>;
            default:
                return null;
        }
    };


    return (
        <div className="flex h-full flex-row bg-gray-50">
            {/* Left navigation menu */}
            <div className="w-1/4 min-w-[260px] bg-white h-full overflow-y-scroll pb-32 border-r p-6">
                <button
                    className="text-primary flex flex-row items-center hover:underline mb-8"
                    onClick={() => navigate("/profile")}
                >
                    <SvgChevronLeft className={'mr-1'}/> Back to Profile
                </button>
                <ul className="space-y-4">
                    {sections.map((section) => (
                        <li
                            key={section.id}
                            className={`flex items-center justify-between p-3 rounded-lg cursor-pointer 
                                ${activeSection === section.id ? "bg-blue-50" : ""}
                                hover:bg-gray-100 transition`}
                            onClick={() => handleSectionClick(section.id)}
                        >
                            <span className="text-gray-800 font-medium truncate">
                                {section.title}
                            </span>
                            {renderStatusIcon(profileSectionStatus[section.id], loadingMap[section.id])}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Main content area */}
            <div className="flex-1 p-10 max-w-[calc(100vw-260px)]">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                    {sections.find((s) => s.id === activeSection)?.title}
                </h2>

                {activeSection === "profile" ? (
                    <EditProfileDetails
                        loading={loadingMap[activeSection]}
                        setLoading={(loading) => handleSetLoading(activeSection, loading)}
                        handleResync={() => handleResync(activeSection)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                    />
                ) : activeSection === "experience" ? (
                    <EditProfileExperienceDetails
                        loading={loadingMap[activeSection]}
                        handleResync={() => handleResync(activeSection)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                    />
                ) : activeSection === "education" ? (
                    <EditProfileEducation
                        loading={loadingMap[activeSection]}
                        setLoading={(loading) => handleSetLoading(activeSection, loading)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                    />
                ) : activeSection === "resume" ? (
                    <EditProfileResumeLinkedin
                        loading={loadingMap[activeSection]}
                        setLoading={(loading) => handleSetLoading(activeSection, loading)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                        handleResync={(linkedinUrl) => handleResync(activeSection, linkedinUrl)}
                    />
                ) : activeSection === "location" ? (
                    <EditProfileLocation
                        loading={loadingMap[activeSection]}
                        setLoading={(loading) => handleSetLoading(activeSection, loading)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                    />
                ) : activeSection === "role" ? (
                    <EditProfileRoles
                        constants={constants}
                        loading={loadingMap[activeSection]}
                        setLoading={(loading) => handleSetLoading(activeSection, loading)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                    />
                ) : activeSection === "company" ? (
                    <EditProfileCompanies
                        trackers={trackers}
                        loading={loadingMap[activeSection]}
                        setLoading={(loading) => handleSetLoading(activeSection, loading)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                    />
                ) : activeSection === "employment" ? (
                    <EditProfileEqualEmployment
                        loading={loadingMap[activeSection]}
                        setLoading={(loading) => handleSetLoading(activeSection, loading)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                    />
                ) : activeSection === "sponsorship" ? (
                    <EditProfileSponsorship
                        loading={loadingMap[activeSection]}
                        setLoading={(loading) => handleSetLoading(activeSection, loading)}
                        goToNextSection={goToNextSection}
                        hasNextSection={hasNextSection()}
                    />
                ) : (
                    <p className="text-gray-600 mb-6">
                        N/A
                    </p>
                )}

            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilePage);
