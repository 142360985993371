import React, {PureComponent} from "react";
import SvgNoteAdd from "../../../components/icons/SvgNoteAdd";
import StatusSelect from "../../../components/atoms/StatusSelect";
import RecruiterStudentPeekApplicationItem from "../RecruiterStudentPeekApplicationItem";
import RecruiterStudentPeekEventItem from "../RecruiterStudentPeekEventItem";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {SIDEBAR_CARD} from "../../../utils/dummy";
import RecruiterStudentPeekEmailItem from "../RecruiterStudentPeekEmailItem";
import CollapseComponent from "../../../components/atoms/CollapseComponent";
import {Button} from "../../../components/atoms/Button";

class RecruiterStudentActivity extends PureComponent {
    state = {
        showMoreEvents: false,
        showMoreEmails: false,
        showMoreApplications:false
    }

    toggleShowMoreEvents = () => {
        this.setState({showMoreEvents: !this.state.showMoreEvents})
    }

    toggleShowMoreEmails = () => {
        this.setState({showMoreEmails: !this.state.showMoreEmails})
    }

    toggleShowMoreApplications = () => {
        this.setState({showMoreApplications: !this.state.showMoreApplications})
    }

    render() {
        const student = this.props.student
        return (
            <div className='flex-1 overflow-y-scroll gap-5 pt-3'>
                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Event History
                    </p>
                    {this.props.studentEvents.length > 0 ?
                        <div className='flex flex-col gap-3'>
                            {this.props.studentEvents.slice(0, 3).map(item =>
                                <RecruiterStudentPeekEventItem event={item}/>
                            )}
                            {this.props.studentEvents.length > 3 &&
                                <>
                                    <CollapseComponent isOpen={this.state.showMoreEvents}>
                                        <div className='flex flex-col gap-3'>
                                            {this.props.studentEvents.slice(3).map(item =>
                                                <RecruiterStudentPeekEventItem event={item}/>
                                            )}
                                        </div>
                                    </CollapseComponent>
                                    <Button variant={'secondary'} onClick={this.toggleShowMoreEvents}>
                                        {this.state.showMoreEvents ? 'Hide' : `Show ${this.props.studentEvents.length - 3} more`}
                                    </Button>
                                </>
                            }
                        </div>
                        :
                        <div className='flex flex-col items-center pt-5 pb-7'>
                            <p className='text-xs text-slate-400'>
                                {student.first_name} hasn't interacted with any events
                            </p>
                        </div>
                    }
                </div>

                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Email History
                    </p>
                    {this.props.companyUserEmails?.length > 0 ?
                        <div className='flex flex-col gap-3'>
                            {this.props.companyUserEmails.slice(0, 3).map(email =>
                                <RecruiterStudentPeekEmailItem email={email} key={email.id}/>
                            )}
                            {this.props.companyUserEmails.length > 3 &&
                                <>
                                    <CollapseComponent isOpen={this.state.showMoreEmails}>
                                        <div className='flex flex-col gap-3'>
                                            {this.props.companyUserEmails.slice(3).map(email =>
                                                <RecruiterStudentPeekEmailItem email={email} key={email.id}/>
                                            )}
                                        </div>
                                    </CollapseComponent>
                                    <Button variant={'secondary'} onClick={this.toggleShowMoreEmails}>
                                        {this.state.showMoreEmails ? 'Hide' : `Show ${this.props.companyUserEmails.length - 3} more`}
                                    </Button>
                                </>
                            }
                        </div>
                        :
                        <div className='flex flex-col items-center pt-5 pb-7'>
                            <p className='text-xs text-slate-400'>
                                No emails have been sent to {student.first_name}
                            </p>
                        </div>
                    }
                </div>

                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Application History
                    </p>
                    {this.props.studentApplications.length > 0 ?
                        <div className='flex flex-col gap-3'>
                            {this.props.studentApplications.slice(0,3).map(item =>
                                <RecruiterStudentPeekApplicationItem application={item} student={student} />
                            )}
                            {this.props.studentApplications.length > 3 &&
                                <>
                                    <CollapseComponent isOpen={this.state.showMoreApplications}>
                                        <div className='flex flex-col gap-3'>
                                            {this.props.studentApplications.slice(3).map(item =>
                                                <RecruiterStudentPeekApplicationItem application={item} student={student} />
                                            )}
                                        </div>
                                    </CollapseComponent>
                                    <Button variant={'secondary'} onClick={this.toggleShowMoreApplications}>
                                        {this.state.showMoreApplications ? 'Hide' : `Show ${this.props.studentApplications.length - 3} more`}
                                    </Button>
                                </>
                            }
                        </div>
                        :
                        <div className='flex flex-col items-center pt-5 pb-7'>
                            <p className='text-xs text-slate-400'>
                                {student.first_name} hasn't interacted with any applications
                            </p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default RecruiterStudentActivity;
