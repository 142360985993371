import {getAuth} from 'firebase/auth'
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {initializeApp} from 'firebase/app'
import { getAnalytics } from "firebase/analytics";

const env = process.env.NODE_ENV;
const vercelEnv = process.env.REACT_APP_VERCEL_ENV;

const firebaseProdConfig = {
  apiKey: "AIzaSyBnOV5iHEjM5Sdzvi8KyCXmyGiKkSBV_y8",
  authDomain: "recruit-u-f79a8.firebaseapp.com",
  projectId: "recruit-u-f79a8",
  storageBucket: "recruit-u-f79a8.appspot.com",
  messagingSenderId: "647665709774",
  appId: "1:647665709774:web:a0a8189b9ae693fff1d69f",
  measurementId: "G-6W1STDNX5C"
};

const firebaseDevConfig = {
  apiKey: "AIzaSyDxKRJkb2V7KvZ4LBvJJkcAq0U--h2D4MI",
  authDomain: "recruit-u-dev.firebaseapp.com",
  projectId: "recruit-u-dev",
  storageBucket: "recruit-u-dev.firebasestorage.app",
  messagingSenderId: "877924500427",
  appId: "1:877924500427:web:807938e4450cc78f856c4e",
  measurementId: "G-HJ64BP9T83"
};

let firebaseConfig;
if (vercelEnv === 'preview') {
    firebaseConfig = firebaseDevConfig
} else if (env === 'production') {
    firebaseConfig = firebaseProdConfig
} else {
    firebaseConfig = firebaseDevConfig
}

// USe firebaseConfig once dev infra fully set up
const firebaseApp = initializeApp(firebaseProdConfig);
const analytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);

export const uploadDoc = async (folder, id, file) => {
  const docRef = ref(firebaseStorage, `${folder}/${id}`);
  const snapshot = await uploadBytes(docRef, file);
  console.log("Uploaded file:", snapshot);

  const docURL = await getDownloadURL(snapshot.ref);
  console.log("docurl", docURL)
  return docURL
};

export const getDocUrl = async (path) => {
  const storageRef = ref(firebaseStorage, path);
  const docURL = await getDownloadURL(storageRef);
  return docURL;
};
