import React, {PureComponent} from "react";
import TagComponent from "../../components/atoms/TagComponent";
import SvgPin from "../../components/icons/SvgPin";
import SvgBriefcase from "../../components/icons/SvgBriefcase";
import SvgArrow from "../../components/icons/SvgArrow";
import SvgCalendar from "../../components/icons/SvgCalendar";
import SvgVideo from "../../components/icons/SvgVideo";
import SvgCheck from "../../components/icons/SvgCheck";
import RecruiterPeekEditStatus from "../modals/RecruiterPeekEditStatus";
import {Link} from "react-router-dom";
import {DateTime} from 'luxon';

class RecruiterStudentPeekEventItem extends PureComponent {

    render() {
        const event = this.props.event;
        let date = null;
        if (!!event.event_date) {
            date = DateTime.fromISO(event.event_date);
        }
        let rsvpDate = null;
        if (!!event.rsvp_date) {
            rsvpDate = DateTime.fromISO(event.rsvp_date);
        }
        return (
            <div className='flex flex-col bg-slate-50 rounded-lg p-3'>
                <div className='flex flex-row gap-4 items-center'>
                    <div className='flex flex-row items-start gap-2'>
                        {rsvpDate && (
                            <div>
                                <p className='text-2xs text-slate-500 mb-1 text-center'>RSVP</p>
                                <div
                                    className='flex flex-col items-center rounded-lg border-neutral-200 bg-white border overflow-hidden'>
                                    <p className='body4-bold uppercase p-0.5 px-2 bg-neutral-100'>
                                        {rsvpDate.toFormat("MMM")}
                                    </p>
                                    <p className='body2-bold p-0.5'>
                                        {rsvpDate.toFormat("d")}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div>
                            <div
                                className='flex flex-col items-center rounded-lg border-neutral-200 bg-white w-11 border overflow-hidden'>
                                <p className='body4-bold uppercase p-0.5 px-2 bg-neutral-100 w-full text-center'>
                                    {date.toFormat("MMM")}
                                </p>
                                <p className='body2-bold p-0.5'>
                                    {date.toFormat("d")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex flex-row items-center gap-3'>
                            {(!!event.class_years?.length || !!event.industry) &&
                                <div className='flex flex-row text-primary items-center gap-1.5'>
                                    {!!event.class_years?.length && <SvgCalendar className={'w-3.5 h-3.5'}/>}
                                    <p className='text-sm text-primary font-semibold'>
                                        {event.industry} {(!!event.class_years?.length && !!event.industry) && '-'} {!!event.class_years && event.class_years.length ? "Class of " + event.class_years.map(year => "'" + year.slice(2)) : null}
                                    </p>
                                </div>
                            }
                            {!!event.location ?
                                <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                                    <div className='flex flex-row items-center gap-1.5'>
                                        {event.location === "Remote" ? <SvgVideo className={'w-3.5 w-3.5'}/> :
                                            <SvgPin className={'w-3.5 w-3.5'}/>}
                                        <p className='text-sm '>
                                            {event.location}
                                        </p>
                                    </div>
                                </div>
                                : null}
                        </div>
                        <p
                            className='text-base font-semibold text-slate-800'>
                            {event.name}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default RecruiterStudentPeekEventItem;
